import React, { useState, useEffect } from 'react';
import 'antd/dist/reset.css';
import { Row, Col, Layout, Button, FloatButton, Space, Card, Steps, theme } from 'antd';
import { Checkbox, Select, Input, InputNumber, Switch, DatePicker, Form, Flex, TreeSelect } from 'antd';
import { PlusCircleOutlined, FormOutlined, GroupOutlined, PlusOutlined, CloseOutlined, UnorderedListOutlined, RetweetOutlined, MinusCircleOutlined, FontSizeOutlined, CheckSquareOutlined, DownSquareOutlined, CalendarOutlined, MailOutlined, FieldNumberOutlined, } from '@ant-design/icons';
import { QuestionTypes } from './QuestionTypes';
import { fetchWrapper } from './fetchWrapper.js';

import { useUser } from './UserContext';

import HeaderComponent from './HeaderComponent';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { useLocation, useParams } from 'react-router-dom';

import './ResumeFormat.css';

const ResumeSection = ({ updateResumeSection, section, editQuestion, deleteQuestion, otherSections }) => {

    const { questions, title, description, groups, availableOn } = section;
    const [selectedQuestions, setSelectedQuestions] = useState([])
    console.log("hi", selectedQuestions);
    const groupHash = {};
    const makeGroup = function () {
        if (!selectedQuestions?.length) {
            return;
        }
        const newGroup = {
            _id: getUniqueId(),
            name: '',
            isRepeative: false,
            questionIdsList: [...selectedQuestions]
        }
        let listGroups = [...(section.groups || []), newGroup];
        let newQuestions = questions.map((t) => {
            if (selectedQuestions.includes(t._id)) {
                t.groupId = newGroup._id
            }
            return t;
        })
        updateResumeSection({ groups: listGroups, questions: [...newQuestions] })
        setSelectedQuestions([]);
    }
    const updateGroup = function (info) {
        if (!(info && info._id)) {
            return;
        }
        let { _id } = info;
        let listGroups = groups.map((item, ind) => {
            if (item._id === _id) {
                return { ...item, ...info };
            }
            return item;
        });
        updateResumeSection({ groups: listGroups })
    }
    const removeGroup = function (info) {
        if (!(info && info._id)) {
            return;
        }
        let { _id } = info;
        let listGroups = groups.filter((t) => {
            return t._id !== _id;
        });
        let newQuestions = questions.map((t) => {
            if (t.groupId == _id) {
                delete t.groupId;
            }
            return t;
        })
        updateResumeSection({ groups: listGroups, questions: newQuestions });
        setSelectedQuestions([]);
    }
    const availabilityData = [];
    const questionAnswersHash = {};
    (Array.isArray(otherSections) ? otherSections : [])?.forEach(thisSection => {
        let eligibleQuestions = [];
        thisSection.questions.forEach(thisQuestion => {
            if (!thisQuestion.options.length) {
                return;
            }
            questionAnswersHash[thisQuestion._id] = {
                options: thisQuestion.options
            }
            eligibleQuestions.push({
                value: thisQuestion._id,
                title: thisQuestion.label,
            })
        });
        if (!eligibleQuestions.length) {
            return;
        }
        availabilityData.push({
            title: thisSection.title,
            children: eligibleQuestions
        });
    })
    const eligibleQuestionAnswers = [];
    let thisAvailableOn = (availableOn || [])[0];
    if (questionAnswersHash[thisAvailableOn?.questionId]?.options?.length) {
        questionAnswersHash[thisAvailableOn?.questionId]?.options.forEach(function (thisAnswer) {
            eligibleQuestionAnswers.push({
                value: thisAnswer,
                label: thisAnswer,
            },)
        })
    }
    const addAvailOn = function () {
        let newAvailableOn = [];
        newAvailableOn = [
            {
                questionId: '',
                sourceSectionId: '',
                answers: [],
                when: 'contains',
            }
        ]
        updateResumeSection({
            availableOn: newAvailableOn
        })
    }

    const changeAvailOn = function (obj) {
        let newAvailableOn = [];
        if (!availableOn?.length) {
            newAvailableOn = 
                {
                    questionId: '',
                    sourceSectionId: '',
                    answers: [],
                    when: 'contains',
                }
            
        } else {
            newAvailableOn = availableOn[0];
        }
        let newObj = { ...newAvailableOn, ...obj };
        updateResumeSection({
            availableOn: [
                newObj
            ]
        })
    }
    if (Array.isArray(groups) && groups.length) {
        groups.forEach(function (thisGroup) {
            groupHash[thisGroup._id] = {
                'start': thisGroup.questionIdsList?.[0],
                end: thisGroup.questionIdsList[thisGroup.questionIdsList?.length - 1],
                ...thisGroup
            };
        })
    }
    let questionsHtml;
    if (!questions.length) {
        questionsHtml = (
            <div className='noQuestionsToShow'>Add Questions</div>
        )
    } else {
        questionsHtml = (
            <>
                <h3>Questions : </h3>

                {questions.map((qi, i) => {
                    let groupInfo = groupHash[qi?.groupId];
                    return (
                        <React.Fragment key={i}>
                            {
                                Boolean(groupInfo?.start == qi._id) && (
                                    <div className='groupStart'>
                                        <Checkbox checked={groupInfo.isRepeative ? true : false} onChange={(e) => {
                                            updateGroup({ _id: groupInfo._id, isRepeative: e.target.checked })
                                        }}>Repeat</Checkbox>
                                        <div style={{ marginLeft: 'auto', 'marginRight': '3px' }} onClick={(e) => {
                                            removeGroup(groupInfo)
                                        }}>
                                            <CloseOutlined />
                                        </div>
                                    </div>
                                )

                            }
                            <div key={i} className='questionContainer' data-type={qi.type}>
                                <div className='questionTypeHeader'>
                                    <div className='questionSNo'>{i + 1}</div>
                                    <div>
                                        <Flex>
                                            {
                                                !Boolean(groupInfo) &&
                                                (<div className='questionOptCover'>
                                                    <Checkbox checked={selectedQuestions.includes(qi._id) ? true : false} onChange={(e) => {
                                                        const updatedSelectedQuestions = selectedQuestions.includes(qi._id)
                                                            ? selectedQuestions.filter(id => id !== qi._id)
                                                            : [...selectedQuestions, qi._id];
                                                        setSelectedQuestions(updatedSelectedQuestions);
                                                    }}></Checkbox>
                                                </div>)
                                            }
                                            <div onClick={(e) => {
                                                editQuestion(qi)
                                            }} className='questionRemoveCover questionOptCover'>
                                                <FormOutlined />
                                            </div>
                                            <div onClick={(e) => {
                                                deleteQuestion(qi)
                                            }} className='questionOptCover questionRemoveCover'>
                                                <CloseOutlined />
                                            </div>
                                        </Flex>
                                    </div>
                                </div>
                                <div className='questionTypeBody'>
                                    <div className='questionLabel'>
                                        {qi.label}
                                        <span style={{ marginLeft: '10px' }}>
                                            {
                                                Boolean(qi.isRepeative) ? <RetweetOutlined /> : <></>
                                            }
                                        </span>
                                    </div>
                                    <div className='questionTypeInfo'>{`${qi.type}`}</div>
                                </div>
                            </div>
                            {

                                Boolean(groupHash[qi?.groupId]?.end == qi._id) && (
                                    <div className='groupEnd'></div>
                                )

                            }
                        </React.Fragment>
                    )
                })
                }
            </>
        )
    }
    return (
        <Row>
            <Col lg={12}>
                {/* <h3>Section Name : </h3> */}
                <div className='sectionNameContainer'>
                    <Input value={title || ''} onChange={(e) => {
                        updateResumeSection({ title: e.target.value })
                    }} placeholder='Section Name' className='form-control-brand' />
                </div>
                <div className='sectionNameContainer'>
                    <Input value={description || ''} onChange={(e) => {
                        updateResumeSection({ description: e.target.value })
                    }} placeholder='Section Description' className='form-control-brand' />
                </div>
                <div className='' style={{ marginTop: '20px' }}>
                    {questionsHtml}
                </div>
                {Boolean(selectedQuestions.length) && (<FloatButton
                    onClick={makeGroup}
                    icon={<GroupOutlined />}
                    type="default"
                    shape="circle"
                    style={{ right: 100, bottom: 100 }}
                />)}
            </Col>
            <Col lg={10} offset={2}>
                {
                    Boolean(availabilityData.length && thisAvailableOn) && (
                        <>
                            <h3>Availability:</h3>
                            <div>
                                <TreeSelect
                                    style={{
                                        width: '100%',
                                    }}
                                    dropdownStyle={{
                                        maxHeight: 400,
                                        overflow: 'auto',
                                    }}
                                    value={thisAvailableOn.questionId}
                                    treeData={availabilityData}
                                    placeholder="Please select"
                                    treeDefaultExpandAll
                                    onChange={(newValue) => {
                                        changeAvailOn({ questionId: newValue })
                                    }}
                                />
                            </div>
                            <div style={{ marginTop: '20px', 'display': 'flex' }}>
                                <Select
                                    value={thisAvailableOn.when}
                                    onChange={(newValue) => {
                                        changeAvailOn({ when: newValue })
                                    }}
                                    style={{ width: '225px' }}
                                    options={[
                                        {
                                            value: 'contains',
                                            label: 'Selected',
                                        },
                                        {
                                            value: 'notContains',
                                            label: 'Not Selected',
                                        },
                                    ]}
                                />
                                <Select
                                    value={thisAvailableOn.answers}
                                    multiple
                                    style={{ marginLeft: '30px', width: '100%' }}
                                    options={eligibleQuestionAnswers}
                                    onChange={(newValue) => {
                                        let newAnswers = Array.isArray(newValue) ? newValue : [newValue];
                                        newAnswers = newAnswers.filter((t) => {
                                            return t
                                        })
                                        changeAvailOn({ answers: newAnswers })
                                    }}
                                />
                            </div>
                        </>

                    )
                }
                {
                    Boolean(availabilityData.length && !thisAvailableOn) && (
                        <Form.Item>
                            <Button
                                type="dashed" onClick={() => addAvailOn()} style={{ width: '60%', }} icon={<PlusOutlined />}
                            >
                                Add Availability
                            </Button>
                        </Form.Item>
                    )
                }
            </Col>
        </Row>
    )
}
const optionsAllowedFor = ['checkbox', 'radio', 'select', 'select-multiple'];
const hasDuplicates = arr => new Set(arr).size !== arr.length;

const AddEditQuestion = ({ questionInfo, updateQuestion, setEditQuestionInfo }) => {
    const [form] = Form.useForm();
    const nameValue = Form.useWatch('label', form);
    const onFinish = (values) => {
        console.log(values);
        updateQuestion({ ...questionInfo, ...values, new: false })
    };
    const { type, _id } = questionInfo;
    const getOptionsHtml = () => {
        return (
            <>
                <Form.Item>
                    <label>Options</label>
                </Form.Item>
                <Form.List name="options"
                    rules={[
                        {
                            validator: async (_, options) => {
                                if (!options || options.length < 1) {
                                    return Promise.reject(new Error('Add atleast one option'));
                                }
                                if (hasDuplicates(options)) {
                                    return Promise.reject(new Error('Options can not be duplicated.'));
                                }
                            },
                        },
                    ]}
                >

                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item
                                    required={false}
                                    key={field.key}
                                >
                                    <Form.Item
                                        {...field}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: false,
                                                message: "Enter value",
                                            },
                                        ]}
                                        noStyle
                                    >
                                        <Input
                                            placeholder="Value"
                                            style={{
                                                width: '60%', marginRight: '10px',
                                            }}
                                        />
                                    </Form.Item>
                                    {fields.length > 1 ? (
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                        />
                                    ) : null}
                                </Form.Item>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed" onClick={() => add()} style={{ width: '60%', }} icon={<PlusOutlined />}
                                >
                                    Add Option
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </>
        )
    }
    // Use useEffect to update form values when questionInfo changes
    useEffect(() => {
        if (questionInfo) {
            questionInfo.isRepeative = questionInfo.isRepeative ?? false;
            form.setFieldsValue(questionInfo);
        }
    }, [questionInfo]);


    return (
        <div className='addEditQuestionCover'>
            <h3 style={{ 'textAlign': 'left' }} >Setup Question</h3>
            <Form initialValues={questionInfo} onFinish={onFinish} form={form} layout="vertical" autoComplete="off">
                <Card
                    headStyle={{ 'textTransform': 'capitalize', background: '#f0f0f0' }}
                    title={(<span>{questionInfo.type}</span>)}
                    actions={[
                        (<Button type="default" onClick={() => { setEditQuestionInfo(null) }}>
                            <PlusCircleOutlined />Cancel
                        </Button>),
                        (<Button type="primary" htmlType="submit">
                            <PlusCircleOutlined />Submit
                        </Button>),

                    ]}
                >

                    <Form.Item name="label" label="Label" rules={[
                        {
                            required: true,
                        },
                        {
                            warningOnly: true,
                        }
                    ]}>
                        <Input required />
                    </Form.Item>
                    <Form.Item name="isRepeative" valuePropName="checked">
                        <Checkbox > Repeat</Checkbox>
                    </Form.Item>
                    {
                        Boolean(optionsAllowedFor.includes(type)) ? getOptionsHtml() : (<></>)
                    }
                </Card>
            </Form>
        </div>
    )
}

const ResumeFormatComponent = () => {
    const navigate = useNavigate();
    const { logout } = useUser();
    const [current, setCurrent] = useState(0);
    const [editQuestionInfo, setEditQuestionInfo] = useState(null);
    const [resumeSections, setResumeSection] = useState([]);
    const [resumeTitle, setResumeTitle] = useState([]);


    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const resumeId = params.get("resumeId");

    useEffect(() => {
        if (resumeId) {
            // Fetch existing resume data using resumeId
            const fetchExistingResumeData = async () => {
                try {
                    const response = await fetchWrapper(`/setup/get-resumeformat`, logout, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ resumeId }), // Include resumeId if it exists
                    }); // Replace with your API endpoint
                    if (response.ok) {
                        const existingResumeData = await response.json();
                        // Set the state with existing data
                        setResumeTitle(existingResumeData.title);
                        setResumeSection(existingResumeData.sections);
                    } else {
                        // Handle error if fetching data fails
                        console.error("Failed to fetch existing resume data.");
                    }
                } catch (error) {
                    // Handle network or other errors
                    console.error("An error occurred while fetching data:", error);
                }
            };

            // Call the function to fetch data
            fetchExistingResumeData();
        }
    }, [resumeId]);


    const saveResumeFormat = async () => {
        try {
            const response = await fetchWrapper('/setup/save-resume-format', logout, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ resumeTitle, resumeSections, resumeId }), // Include resumeId if it exists
            });

            if (response.ok) {
                const responseData = await response.json();

                if (resumeId) {
                    // Updated an existing resume format
                    console.log('Form data updated successfully!');
                    console.log('Updated Resume:', responseData.updatedResume);
                    message.success('Form data updated successfully!');
                } else {
                    // Created a new resume format
                    console.log('Form data saved successfully!');
                    message.success('Form data saved successfully!');
                }
            } else {
                // Request failed, handle errors here
                console.error('Failed to save form data.');
                message.error('Failed to save form data.');
            }
        } catch (error) {
            // Handle any network or other errors here
            console.error('An error occurred:', error);
            message.error('An error occurred. Please try again later.');
        }
    };



    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const onStepChange = (value) => {
        console.log('onChange:', value);
        setCurrent(value);
    };


    const updateResumeSection = function (updatedItem) {
        setResumeSection((prevItems) => {
            return prevItems.map((item, ind) => {
                if (current === ind) {
                    return { ...item, ...updatedItem };
                }
                return item;
            });
        });
    }


    const addSection = function (ind) {
        console.log("calling add section", resumeSections.length);
        let newSection = {};
        let updatedSections = [];
        if (ind !== undefined && ind >= 0 && ind <=resumeSections.length) {
            newSection = { 'title': ` Section ${ind+1}`, questions: [], };
            updatedSections = [...resumeSections];
            updatedSections.splice(ind, 0, newSection);
            setCurrent(ind) 
           
        }
       
        else{
            newSection = { 'title': ` Section ${resumeSections.length + 1}`, questions: [], };
            updatedSections = [...resumeSections, newSection];
            setCurrent(updatedSections.length - 1) 
        }


       
        
        console.log("updatedSections", updatedSections);
        setResumeSection(updatedSections);
      
    }

    const makeGroup = function () {
        console.log("calling add section", resumeSections.length);
        const newSection = { 'title': ` Section ${resumeSections.length + 1}`, questions: [], };
        const updatedSections = [...resumeSections, newSection];
        console.log("updatedSections", updatedSections);
        setResumeSection(updatedSections);
        setCurrent(updatedSections.length - 1)
    }
    const removeSection = (sectionIndex) => {
        const updatedSections = resumeSections.filter((_, index) => index !== sectionIndex);
        setResumeSection(updatedSections);
        setCurrent(Math.max(sectionIndex - 1, 0))
    };

    const items = resumeSections.map((item, i) => ({
        key: item.name,
        // title: item.title,
        status: i === current ? 'process' : 'wait'
    }));

    const CTA_NEXT = (
        <Button disabled={(current < resumeSections.length - 1) ? false : true} type="primary" onClick={() => next()}>
            Next
        </Button>
    )

    const CTA_PREV = (
        <Button disabled={current > 0 ? false : true} style={{ margin: '0 8px', }} onClick={() => prev()} >
            Previous
        </Button>
    )
    const getQuestionTypeIcon = (questionType) => {
        let ret = (
            <FontSizeOutlined />
        )
        if (questionType.type == 'richtext') {
            ret = (<UnorderedListOutlined />)
        }
        if (questionType.type == 'number') {
            ret = (<FieldNumberOutlined />)
        }
        if (questionType.type == 'email') {
            ret = (<MailOutlined />);
        }
        if (questionType.type == 'date' || questionType.type == 'datetime') {
            ret = (<CalendarOutlined />)
        }
        if (questionType.type == 'select' || questionType.type == 'select-multiple') {
            ret = (<DownSquareOutlined />)
        }
        if (questionType.type == 'checkbox') {
            ret = (<CheckSquareOutlined />)
        }
        return ret;
    }
    const getQuestionTypeDisplay = (questionType) => {
        let ret = (
            <></>
        );
        if (questionType.type === 'checkbox') {
            ret = (
                <Checkbox disabled >Option</Checkbox>
            )
        }
        if (questionType.type === 'select') {
            ret = (
                <Select
                    disabled
                    style={{ width: '100%' }}
                    options={[
                        {
                            value: 'Option1',
                            label: 'Option1',
                        },
                    ]}
                />
            )
        }
        if (questionType.type === 'select-multiple') {
            ret = (
                <Select
                    disabled
                    mode="multiple"
                    style={{ width: '100%' }}
                    options={[
                        {
                            value: 'Option1',
                            label: 'Option1',
                        },
                    ]}
                />
            )
        }
        if (questionType.type === 'text' || questionType.type == 'richtext') {
            ret = (
                <Input disabled />
            )
        }
        if (questionType.type === 'number') {
            ret = (
                <InputNumber placeholder='12345' disabled />
            )
        }
        if (questionType.type === 'switch') {
            ret = (
                <Switch disabled />
            )
        }
        if (questionType.type === 'date') {
            ret = (
                <DatePicker disabled format="YYYY-MM-DD HH:mm:ss" />
            )
        }
        if (questionType.type === 'email') {
            ret = (
                <Input disabled placeholder='email@email.com' />
            )
        }
        if (questionType.type === 'datetime') {
            ret = (
                <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" />
            )
        }
        return ret;
    }
    const addQuestion = function (type) {
        let thisObj = resumeSections[current];
        if (!thisObj) {
            resumeSections[current] = { "questions": [] };
            thisObj = resumeSections[current];
        }
    
        const newQuestion = {
            _id: getUniqueId(),
            type: type,
            label: '',
            options: [],
        };
        let questions = [...thisObj.questions, newQuestion];
    
        console.log(questions);
        updateResumeSection({ questions: questions });
        setEditQuestionInfo({ ...newQuestion, new: true });
    }
    
    const editQuestion = (questionInfo) => {
        let newobj = { 'label': '', ...questionInfo };
        setEditQuestionInfo(newobj);
    }
    const deleteQuestion = (questionInfo) => {
        let thisObj = resumeSections[current];
        let questions = thisObj.questions.filter((t) => {
            return t._id !== questionInfo._id;
        });
        updateResumeSection({ questions: questions });
        if (editQuestionInfo && editQuestionInfo._id === questionInfo._id) {
            setEditQuestionInfo(null);
        }
    };
    const updateQuestion = (questionInfo) => {
        if (questionInfo && questionInfo._id) {
            let { _id } = questionInfo;
            let thisObj = resumeSections[current];
            let { questions } = thisObj;
            questions = questions.map((item, ind) => {
                if (item._id === _id) {
                    return { ...item, ...questionInfo };
                }
                return item;
            });
            updateResumeSection({ questions: questions });
            setEditQuestionInfo(null);
        }
    };
    let otherSections = resumeSections.filter((t, ind) => {
        return ind < current;
    })

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <HeaderComponent logout={logout} />
            <div style={{ padding: '20px', marginTop: "60px" }}>
                <Row gutter={[24, 16]}>
                    <Col md={6}>
                        {
                            !Boolean(editQuestionInfo) ? (
                                <>
                                    <h3 style={{ 'textAlign': 'left' }} >Components</h3>
                                    <Input className='form-control-brand' placeholder='Search question component' />
                                    <div className='questionTypeListCover'>
                                        <Row gutter={16}>
                                            {QuestionTypes.map((t) => {
                                                let questionTypeTitle = (
                                                    <span>{t.label}</span>
                                                )
                                                return (
                                                    <Col span={12} style={{ marginTop: '20px' }} >
                                                        <Card className='questionTypeCard' title={(
                                                            <div className='questionTypeCardTitleCover'>
                                                                <div className='questionTypeIcon'>{getQuestionTypeIcon(t)}</div>
                                                                <div className='questionTypeCardTitle'>{questionTypeTitle}</div>
                                                            </div>
                                                        )} bordered={false} onClick={(e) => {
                                                            addQuestion(t.type)
                                                        }}>
                                                            {getQuestionTypeDisplay(t)}
                                                        </Card>
                                                        <Space></Space>
                                                    </Col>
                                                )
                                            })}
                                            {/* {
                                                (
                                                    <Col span={12} style={{ marginTop: '20px' }} >
                                                        <Card className='questionTypeCard' title={(
                                                            <div className='questionTypeCardTitleCover'>
                                                                <div className='questionTypeIcon'></div>
                                                                <div className='questionTypeCardTitle'>Group</div>
                                                            </div>
                                                        )} bordered={false} onClick={(e) => {
                                                            manageGroup()
                                                        }}>
                                                            Group
                                                        </Card>
                                                        <Space></Space>
                                                    </Col>
                                                )
                                            } */}
                                        </Row>
                                    </div>
                                </>
                            ) : (
                                <AddEditQuestion setEditQuestionInfo={setEditQuestionInfo} questionInfo={editQuestionInfo} updateQuestion={updateQuestion} />
                            )
                        }
                    </Col>
                    <Col md={18}>
                        <h3>Resume Setup</h3>

                        <Input style={{ marginBottom: '20px', marginTop: '10px' }}
                            placeholder='Resume Title'
                            value={resumeTitle}
                            onChange={(e) => setResumeTitle(e.target.value)}
                        />
                        {resumeSections.length > 0 ? (
                            <div className='sectionsList'>
                                <Steps current={current} items={items} onChange={onStepChange}
                                />
                                <div className='sectionInfo'>{
                                    <ResumeSection otherSections={otherSections} updateResumeSection={updateResumeSection} deleteQuestion={deleteQuestion} editQuestion={editQuestion} section={resumeSections[current]} />
                                }</div>
                                <div
                                    style={{
                                        marginTop: 24,
                                    }}
                                >
                                </div>
                            </div>
                        ) : (<></>)}
                        <div className='SectionCTACover'>
                            <Row gutter={[24, 16]}>
                                <Col md={6}></Col>
                                <Col md={18}>
                                    <Button danger onClick={(e) => {
                                        removeSection(current)
                                    }} style={{ marginRight: '100px' }} >
                                        Remove Section
                                    </Button>
                                    <Button type='primary' onClick={() => {addSection(current+1)}} >
                                        Add Section Next
                                    </Button>
                                    <Button type='primary' onClick={() => {addSection()}} >
                                        Add Section to Last
                                    </Button>
                                    <Button type='primary' style={{ float: 'right' }} onClick={saveResumeFormat} >
                                        Save Resume Format
                                    </Button>
                                </Col>
                            </Row>
                        </div>

                        {/* <FloatButton
                            icon={<PlusOutlined />}
                            type="primary"
                            shape="circle"
                            onClick={addSection}
                            style={{ right: 50 }}
                        /> */}
                    </Col>

                </Row>
            </div>
        </Layout>
    );
};
let counter = 1;
function getUniqueId() {
    return counter++
}
export default ResumeFormatComponent;