
import React, { useEffect, useState, useRef } from 'react';
import HeaderComponent from './HeaderComponent';
import { useUser } from './UserContext';
import { useNavigate } from 'react-router-dom';
import { fetchWrapper } from './fetchWrapper.js';
import { Layout, Button, Divider, List, Card, Menu, Dropdown, Modal, notification } from 'antd';
import { FileTextOutlined, UserOutlined, MailOutlined, PhoneOutlined, NumberOutlined, EllipsisOutlined, EditOutlined, DeleteOutlined, PlusOutlined, EyeOutlined, DownOutlined, LockOutlined, LogoutOutlined } from '@ant-design/icons';
import './ConsumerDashboard.css';
import { Switch } from 'antd';

const { Content, Footer } = Layout;


function ConsumerDashboard() {
  const navigate = useNavigate();
  const { logout } = useUser();
  const [resumes, setResumes] = useState([]);
  const [profile, setProfile] = useState([]);
  const [profilePhoto, setProfilePhoto] = useState(null);

  // Ref for the file input element
  const fileInputRef = useRef(null);

  const [showAllResumes, setShowAllResumes] = useState(false);

  // Toggle function
  const toggleShowAllResumes = () => {
    setShowAllResumes(!showAllResumes);
  };

  const ResumeDataConfig = {
    "name": "6539082f406a5dc3f1c0d613",
    "address": "655c7d37da87599fee1deca7",
    "contactNo": "655c7d37da87599fee1deca6",
    "contactEmail": "655c7d37da87599fee1deca5",
    "qualification": "655c7d37da87599fee1deca8",
    state_registration: "_id_state_registration",
    lang_test: "655c7d37da87599fee1deca9",
    gmc_status: '655c7d37da87599fee1decaa',
    other_rel_info: '655db215da87599fee1deea4',
    personal_statement: '655c7d37da87599fee1decab',
    observership: '_id_observership',
    course: '65390b58a22439c556803a28',
    accomplish: '65390b58a22439c556803a2a',
    research: '654b727162f3e6da7f42a882',
    teaching: '65390b58a22439c556803a2e',
    leadership: '65390b58a22439c556803a30',
    intern_start_date: '655c7d37da87599fee1decad',
    intern_end_date: '655c7d37da87599fee1decae',
    intern_hospital: '655c7d37da87599fee1decaf',
    intern_college: '655c7d37da87599fee1decb0',
    intern_work: '655c7d37da87599fee1decb1'
  }
  useEffect(() => {
    const fetchResumes = async () => {
      try {
        // Replace '/api/listResumes' with your actual API endpoint

        const response = await fetchWrapper('/user/listResumes', logout, {
          method: 'GET',
          // credentials: 'include',  // Include this line
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }

        const data = await response.json();
        setResumes(data);
      } catch (error) {
        console.error('Error fetching resumes:', error);
      }
    };

    

    fetchResumes();
    document.body.classList.add("resumeDashboardPage");
  }, []); // Empty dependency array ensures this runs once after the initial render

  useEffect(() => {
    const fetchUserDetails = async () => {
        try {
            const response = await fetchWrapper('/user/details',logout, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
              //  credentials: 'include' // This is necessary to send cookies along with the request
            });
            const data = await response.json();
            setProfile(data);
           // setUser(data);
        } catch (error) {
            notification.error({ message: 'Error fetching user details.' });
        } finally {
           // setLoading(false);
        }
    };

    fetchUserDetails();
}, []);


  const getData = (ref, ind) => {
    let key = `${ResumeDataConfig[ref]}`
    if (ind) {
      key = `${key}_${ind}`;
    }
    return ResumeData[key]
  }
  const checkAvailData = (ref, ind) => {
    let key = `${ResumeDataConfig[ref]}`
    if (ind) {
      key = `${key}_${ind}`;
    }
    return ResumeData.hasOwnProperty(key)
  }


  const handleAdd = () => {
    window.location.href = `/addEditResume?resumeId=new`;
  };

  const handleDelete = (resumeId) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this resume?',
      content: 'This action cannot be undone.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No, cancel',
      onOk: () => {
        deleteResume(resumeId);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const deleteResume = async (resumeId) => {
    try {

      const response = await fetchWrapper('/user/delete-resume', logout, {
        method: 'DELETE',
        // credentials: 'include',  // Include this line
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ resumeId: resumeId })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const data = await response.json();
      if (response.status === 200) {
        notification.success({ message: 'Deleted successfully!' });
        setResumes(prevResumes => prevResumes.filter(resume => resume._id !== resumeId));
      }
      else {
        notification.error({ message: 'Error delete data. Try again later.' });
      }


    } catch (error) {
      console.error('Failed to delete resume:', error);
      notification.error({ message: 'Failed to delete resume:', error });
      // Optionally, show an error notification
    }
  };

  const handleChangeProfile = () => {
    window.location.href = "/consumer-profile"
  }

  const handleLogout = () => {
    logout();
  }


  const handleEdit = (resumeId) => {

    window.location.href = `/addEditResume?resumeId=` + resumeId;
    // Delete resume logic...
  };

  const handleUpgrade = (resumeId) => {

    window.location.href = `/addEditResume?resumeId=` + resumeId + `&upgrade=true`;
    // Delete resume logic...
  };

  const handleView = (resumeId) => {

    window.location.href = `/view-resume-pdf?resumeId=` + resumeId;
    // Delete resume logic...
  };

  // const handleDownload = (resumeId) => {

  //   window.location.href = `/view-resume-pdf?resumeId=` + resumeId+`&triggerPrint=true`;
  //   // Delete resume logic...
  // };

  // const handleProfilePhotoChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = function (e) {
  //       setProfilePhoto(e.target.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };



  const getActionsMenu = (resume) => (
    <Menu>
      {/* <Menu.Item key="1" icon={<EyeOutlined />} onClick={() => navigate(`/view-resume/${resume._id}`)}>
        View Resume
      </Menu.Item> */}

      <Menu.Item key="1" icon={<EyeOutlined />} onClick={() => handleView(resume._id)}>
        View Resume
      </Menu.Item>
      {/* <Menu.Item key="1" icon={<EyeOutlined />} onClick={() => handleDownload(resume._id)}>
        Download Resume
      </Menu.Item> */}
      <Menu.Item key="2" icon={<EditOutlined />} onClick={() => handleEdit(resume._id)}>
        Edit
      </Menu.Item>
      <Menu.Item key="3" icon={<DeleteOutlined />} onClick={() => handleDelete(resume._id)}>
        Delete
      </Menu.Item>
      {resume?.upgrade && (
        <Menu.Item key="4" icon={<EditOutlined />} onClick={() => handleUpgrade(resume._id)}>
          Update to Latest Version
        </Menu.Item>
      )}
    </Menu>
  );
  const latestResume = resumes.length > 0 ? resumes[0] : null;



  // if (!latestResume || showAllResumes) {
  //   return (
  //     <Layout style={{ minHeight: '100vh' }}>
  //       <HeaderComponent logout={logout} />
  //       <Content style={{ padding: '50px 50px 0', marginTop: 64 }}>
  //       {resumes.length > 1 ? (  
  //       <div style={{ marginBottom: 16 }}>
  //         <span style={{ marginRight: 8 }}>Show All Resumes:</span>
  //         <Switch checked={showAllResumes} onChange={toggleShowAllResumes} />
  //       </div> ): <div></div>}

  //         <div style={{ background: '#fff', padding: 24, minHeight: 380 }}>
  //           <h2>Your Resumes</h2>
  //           <Divider />
  //           {resumes.length > 0 ? (
  //             <List
  //               grid={{ gutter: 16, column: 3 }}
  //               dataSource={resumes}
  //               renderItem={(resume, index) => (
  //                 <List.Item>
  //                   <Card
  //                     title={`${resume["resumeFormat"]["title"]} - v${resume["resumeFormat"]["sectionsVersion"]}`}
  //                     bordered={false}
  //                     extra={
  //                       <Dropdown overlay={getActionsMenu(resume)} trigger={['click']}>
  //                         <Button icon={<DownOutlined />}>Actions</Button>
  //                       </Dropdown>
  //                     }
  //                   >
  //                   </Card>
  //                 </List.Item>
  //               )}
  //             />
  //           ) : (
  //             <div style={{ textAlign: 'center', marginTop: '20px' }}>
  //               <Button
  //                 icon={<PlusOutlined />}
  //                 type="primary"
  //                 onClick={handleAdd}
  //               >
  //                 Add New Resume
  //               </Button>
  //             </div>
  //           )}
  //         </div>
  //       </Content>
  //       <Footer style={{ textAlign: 'center' }}>Resume Generator ©2023</Footer>
  //     </Layout>
  //   );
  // }



  const getQuestionAnswer = ({ thisQuestion, thisSectionAnswer }) => {
    if (thisQuestion._id == "655c7d37da87599fee1decad") {
      debugger;
    }
    let thisAnswer = thisSectionAnswer.questions.find((t) => {
      return t.questionId == thisQuestion._id
    })
    return (
      <div className='questionAnswer'>
        {thisAnswer?.answer.value || '-'}
      </div>
    )
  }
  let hashData = {};
  latestResume?.resumeAnswer.forEach((thisSection) => {
    thisSection.questions.forEach((thisQues) => {
      let val = thisQues?.answer?.value;
      if (Array.isArray(val)) {
        val = val.join()
      }
      hashData[thisQues.questionId] = val;
    })
  })

  let ignoreQuestionIds = []
  latestResume?.resumeFormat?.sections.forEach(section => {
    if (Array.isArray(section.availableOn)) {
      section.availableOn.forEach(availableOn => {
        if (availableOn.questionId) {
          ignoreQuestionIds.push(availableOn.questionId);
        }
      });
    }
  });

  // const ignoreQuestionIds = latestResume?.resumeFormat?.sections.flatMap(section => {
  //   return section.availableOn && Array.isArray(section.availableOn)
  //     ? section.availableOn.map(availableOn => availableOn.questionId)
  //     : [];
  // });

  let ResumeData = hashData;
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <HeaderComponent logout={logout} />
      <Content style={{ padding: '50px 50px 0', marginTop: 64 }}>

        {resumes.length > 1 ? (
          <div style={{ marginBottom: 16 }}>
            <span style={{ marginRight: 8 }}>Show All Resumes:</span>
            <Switch checked={showAllResumes} onChange={toggleShowAllResumes} />
          </div>) : <div></div>}

        <div className="consumerDashboardViewResumePage">
          <div className='row'>
            <div className='col-3'>
              <div className='dashboardStickyCover'>
                <div style={{ marginBottom: '20px' }} className='userProfileInfo'>
                  <div className='cardBrand cardBrand2'>
                    <div className='cardBody'>
                      <div className='' style={{ textAlign: 'center' }}>

                        <div className='userDetails' style={{ textAlign: 'center', padding: '20px' }}>
                          <div style={{ position: 'relative', display: 'inline-block', marginBottom: '20px' }}>
                            <div className='profilePhoto'>
                              {profile.profilePhoto ? (
                                <img src={profile.profilePhoto} alt="Profile" style={{ width: '100px', height: '100px', borderRadius: '50%', border: '2px solid #ddd', objectFit: 'cover' }} />
                              ) : (
                                <UserOutlined style={{ fontSize: '100px', color: '#ddd' }} />
                              )}
                              <input
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                              />
                              {/* <Button
                                onClick={() => fileInputRef.current.click()}
                                type="primary"
                                icon={<EditOutlined />}
                                style={{
                                  position: 'absolute',
                                  bottom: '0',
                                  right: '0',
                                  color: '#000',
                                  padding: '5px 10px',
                                  borderRadius: '50%',
                                  backgroundColor: '#fff',
                                  border: '1px solid #ddd',
                                  fontSize: '14px'
                                }}>
                              </Button> */}
                            </div>
                          </div>

                          <div className="profileName" style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '20px' }}>
                          {`${profile["firstName"] || ""} ${profile["lastName"] || ""}`}

                          </div>
                        </div>

                        {/* <div style={{ margin: '5px', fontSize: '16px', color: '#333' }} dangerouslySetInnerHTML={{ __html: getData("qualification") }}></div> */}

                        <div style={{ fontSize: '14px', color: '#555' }}>
                          <div style={{ marginBottom: '10px' }}>
                            <MailOutlined style={{ paddingRight: '10px', fontSize: '16px', color: '#007bff' }} />
                            {profile["username"]|| ''}
                          </div>
                          <div style={{ marginBottom: '10px' }}>
                            <PhoneOutlined style={{ paddingRight: '10px', fontSize: '16px', color: '#28a745' }} />
                            {profile["phoneNumber"] || '-'}
                          </div>
                          {/* <div style={{ marginBottom: '10px' }}>
                            <NumberOutlined style={{ paddingRight: '10px', fontSize: '16px', color: '#17a2b8' }} />
                            <span dangerouslySetInnerHTML={{ __html: (getData("address") || ' No Data') }}></span>
                          </div> */}
                        </div>
                        <div className='divider' style={{ height: '1px', backgroundColor: '#e0e0e0', margin: '20px 0' }}></div>

                        {latestResume?.resumeFormat && (
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                            <h3 style={{
                              fontSize: '18px',
                              fontWeight: '500',
                              color: '#333',
                              marginRight: '10px',
                              marginBottom: '0' // Remove bottom margin to align with the button
                            }}>
                              {`${latestResume.resumeFormat.title || ''} - v${latestResume.resumeFormat.sectionsVersion || ''}`}
                            </h3>
                            <Dropdown overlay={getActionsMenu(latestResume)} trigger={['click']}>
                              <Button
                                icon={<EllipsisOutlined />}
                                style={{ backgroundColor: '#f0f0f0', borderColor: '#d9d9d9', color: '#000' }}>

                              </Button>
                            </Dropdown>
                          </div>
                        )}




                        <div style={{ textAlign: 'center', margin: '20px 0' }}>
                          <Button
                            type="primary"
                            icon={<LockOutlined />}
                            style={{ marginRight: '10px', backgroundColor: '#1d417b', borderColor: '#1d417b', color: '#fff' }}
                            onClick={handleChangeProfile} // Replace with your actual handler
                          >
                            View Profile
                          </Button>
                          <Button
                            type="primary"
                            icon={<LogoutOutlined />}
                            style={{ backgroundColor: '#dc3545', borderColor: '#dc3545', color: '#fff' }}
                            onClick={handleLogout} // Replace with your actual logout handler
                          >
                            Logout
                          </Button>
                        </div>







                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div className='col-8'>
              <div className="dashboardStickyCover" style={{ padding: '0px 10px' }}>

                {
                
                
                !latestResume || showAllResumes ? (
                  <>

                    <div style={{ background: '#fff', padding: 24, minHeight: 380 }}>
                      <h2>Your Resumes</h2>
                      <Divider />
                      {resumes.length > 0 ? (
                        <List
                          grid={{ gutter: 16, column: 3 }}
                          dataSource={resumes}
                          renderItem={resume => (
                            <List.Item>
                              <Card
                                title={`${resume["resumeFormat"]["title"]} - v${resume["resumeFormat"]["sectionsVersion"]}`}
                                bordered={false}
                                extra={
                                  <Dropdown overlay={getActionsMenu(resume)} trigger={['click']}>
                                    <Button icon={<DownOutlined />}>Actions</Button>
                                  </Dropdown>
                                }
                              />
                            </List.Item>
                          )}
                        />
                      ) : (
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                          <Button icon={<PlusOutlined />} type="primary" onClick={handleAdd}>
                            Add New Resume
                          </Button>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  // Render the latest resume's sections here
                   
                  <div style={{ marginRight: '20px' }}>
                    {

                      
                      
                      latestResume?.resumeFormat?.sections.map((section, sectionIndex) => {
                        const answersSection = latestResume.resumeAnswer.find(ansSec => ansSec.sectionId.toString() === section._id.toString());

                        const hasAnswers = answersSection?.questions.some(question =>
                          section.questions.some(secQuestion => secQuestion._id.split('_')[0] === question.questionId.split('_')[0] && question.answer?.value)
                        );
                        

                        if (!hasAnswers) {
                          return null;
                        }

                        const repeatableGroup = section.groups && section.groups.find(group => group.isRepeative && group.questionIdsList.length > 0);
                        
                        const repeatSets = answersSection?.questions.reduce((acc, question) => {
                          const parts = question.questionId.split('_');
                          const suffix = parts.length > 1 ? parseInt(parts[1], 10) : 0;
                          return Math.max(acc, suffix);
                        }, 0);
                  

  

                        return (
                          <div key={sectionIndex} className="resume-segment">
                            <div className="cardBrand">
                              <div className="cardHeader">{section.title}</div>
                              <div className="cardBody text-justify">
                                {repeatableGroup ? (
//                                   <table className="table table-bordered">
//                                     <thead>
//                                       <tr>
//                                         {repeatableGroup.questionIdsList.map((questionId, qIndex) => {
                                          
//                                           const baseQuestionId = questionId.split('_')[0];
//                                           if (ignoreQuestionIds.indexOf(baseQuestionId) === -1) {
//                                             return (
//                                               <th key={qIndex}>
//                                                 {section.questions.find(q => q._id === baseQuestionId)?.label || ''}
//                                               </th>
//                                             );
//                                           }
//                                           return null; // Return null for items that should be ignored
//                                         })}
//                                       </tr>
//                                     </thead>
//                                     <tbody>
//                                       {[...Array(repeatSets + 1)].map((_, repeatIndex) => (
//                                         <tr key={repeatIndex}>
//                                           {section.questions.filter(q => !q._id.includes('_')).map((question, qIndex) => {
//                                             const suffix = repeatIndex > 0 ? `_${repeatIndex}` : '';
//                                             const answer = answersSection?.questions.find(q => q.questionId === `${question._id}${suffix}`);
//                                             if (ignoreQuestionIds.indexOf(question._id) === -1) {
//                                             return (
//                                               <td key={qIndex}>
//   <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }} dangerouslySetInnerHTML={{ __html: answer?.answer?.value || '' }}></div>
// </td>

//                                             );
//                                             }
//                                             return null; 

//                                           })}
//                                         </tr>
//                                       ))}
                                  
//                                     </tbody>
//                                   </table>

<div className="custom-table">
<div className="header-row">
  {repeatableGroup.questionIdsList.map((questionId, qIndex) => {
    const baseQuestionId = questionId.split('_')[0];
    if (ignoreQuestionIds.indexOf(baseQuestionId) === -1) {
      return (
        <div key={qIndex} className="header-cell bordered table-header">
        
            {section.questions.find(q => q._id === baseQuestionId)?.label || ''}
        
        </div>
      );
    }
    return null; // Return null for items that should be ignored
  })}
</div>


  <div className="table-body">
    {[...Array(repeatSets + 1)].map((_, repeatIndex) => (
      <div key={repeatIndex} className="table-row">
        {section.questions.filter(q => !q._id.includes('_')).map((question, qIndex) => {
          const suffix = repeatIndex > 0 ? `_${repeatIndex}` : '';
          const answer = answersSection?.questions.find(q => q.questionId === `${question._id}${suffix}`);
          if (ignoreQuestionIds.indexOf(question._id) === -1) {
            return (
              <div key={qIndex} className="table-cell">
                <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }} dangerouslySetInnerHTML={{ __html: answer?.answer?.value || '' }}></div>
              </div>
            );
          }
          return null;
        })}
      </div>
    ))}
  </div>
</div>

                                ) : (
                                  section.questions.map((question, questionIndex) => {
                                    const answer = answersSection?.questions.find(q => q.questionId === question._id);
                                    if(answer?.answer?.value !== undefined){
                                      return (
                                        <div key={questionIndex} className='innerSegmentCover'>
                                          <strong>{question.label}:</strong>
                                          <div style={{ margin: '10px',wordWrap: 'break-word', whiteSpace: 'normal'  }} dangerouslySetInnerHTML={{ __html: answer?.answer?.value || '' }}></div>
                                        </div>
                                      );
                                    }
                                    else{
                                      return (
                                        <div key={questionIndex} className='innerSegmentCover'>
                                        </div>
                                      );
                                    }

                                   
                                  })
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    }


                    {/* <div className="academicInfo resume-segment">
                      <div className="cardBrand">
                        <div className="cardHeader">Academic & GMC Details</div>
                        <div className="cardBody text-justify">
                          <div className='innerSegmentCover'>
                    <div style={{ fontWeight: 'bold' }}>Qualifications:</div>
                    <div style={{ margin: '10px' }} dangerouslySetInnerHTML={{ __html: getData("qualification") }}></div>
                  </div>
                          <div className='innerSegmentCover'>
                            <div style={{ fontWeight: 'bold' }}>State Registration:</div>
                            <div style={{ margin: '10px' }} dangerouslySetInnerHTML={{ __html: getData("state_registration") || '-' }}></div>
                          </div>
                          <div className='innerSegmentCover'>
                            <div style={{ fontWeight: 'bold' }}>Language test:</div>
                            <div style={{ margin: '10px' }} dangerouslySetInnerHTML={{ __html: getData("lang_test") || '-' }}></div>
                          </div>
                          <div className='innerSegmentCover'>
                            <div style={{ fontWeight: 'bold' }}>GMC Status:</div>
                            <div style={{ margin: '10px' }} dangerouslySetInnerHTML={{ __html: getData("gmc_status") || '-' }}></div>
                          </div>
                          <div className='innerSegmentCover'>
                            <div style={{ fontWeight: 'bold' }}>Other Relevant Information:</div>
                            <div style={{ margin: '10px' }} dangerouslySetInnerHTML={{ __html: getData("other_rel_info") || '-' }}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="personalStatement resume-segment">
                      <div className="cardBrand">
                        <div className="cardHeader">Personal Statement</div>
                        <div className="cardBody text-justify" dangerouslySetInnerHTML={{ __html: getData("personal_statement") }}></div>
                      </div>
                    </div>
                    <div className="observership resume-segment">
                      <div className="cardBrand">
                        <div className="cardHeader">Observership</div>
                        <div className="cardBody" dangerouslySetInnerHTML={{ __html: getData("observership") }}></div>
                      </div>
                    </div>
                    <div className="employmentDetails customEmp resume-segment">
                      <table className="table table-bordered">
                        <thead>
                          <tr className="align-middle">
                            <th>Employment Dates </th>
                            <th>Previous Employment</th>
                            <th>Department / Scope of Work</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            [...new Array(100)].filter((t, i) => {
                              return checkAvailData('intern_start_date', i)
                            }).map((t, i) => {
                              return (<tr key={i}>
                                <td className="text-center">{getData('intern_start_date', i)} - {getData('intern_end_date', i)} </td>
                                <td className="text-center">{getData('intern_hospital', i) || getData('intern_college', i)} </td>
                                <td className="text-justify">
                                  {getData('intern_work', i)}
                                </td>
                              </tr>)
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                    <div className="courses resume-segment">
                      <div className="cardBrand">
                        <div className="cardHeader">Courses</div>
                        <div className="listMultiple">
                          {
                            [...new Array(100)].filter((t, i) => {
                              return checkAvailData('course', i)
                            }).map((t, i) => {
                              return (
                                <div key={i} className="singleItem"><span></span>{getData('course', i)}</div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                    <div className="accomplishments resume-segment">
                      <div className="cardBrand">
                        <div className="cardHeader">Accomplishments & Volunteering works</div>
                        <div className="listMultiple">
                          {
                            [...new Array(100)].filter((t, i) => {
                              return checkAvailData('accomplish', i)
                            }).map((t, i) => {
                              return (
                                <div key={i} className="singleItem"><span></span>{getData('accomplish', i)}</div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                    <div className="research resume-segment">
                      <div className="cardBrand">
                        <div className="cardHeader">Research & Publication</div>
                        <div className="listMultiple">
                          {
                            [...new Array(100)].filter((t, i) => {
                              return checkAvailData('research', i)
                            }).map((t, i) => {
                              return (
                                <div key={i} className="singleItem"><span></span>{getData('research', i)}</div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                    <div className="teaching resume-segment">
                      <div className="cardBrand">
                        <div className="cardHeader">Teaching Experience & other information</div>
                        <div className="listMultiple">
                          {
                            [...new Array(100)].filter((t, i) => {
                              return checkAvailData('teaching', i)
                            }).map((t, i) => {
                              return (
                                <div key={i} className="singleItem"><span></span>{getData('teaching', i)}</div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                    <div className="leadership resume-segment">
                      <div className="cardBrand">
                        <div className="cardHeader">Leadership Skills & Extra-curricular activities</div>
                        <div className="listMultiple">
                          {
                            [...new Array(100)].filter((t, i) => {
                              return checkAvailData('leadership', i)
                            }).map((t, i) => {
                              return (
                                <div key={i} className="singleItem"><span></span>{getData('leadership', i)}</div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>  */}

                    {/* last div closing            */}
                  </div>
                )}
              </div>
            </div>
          </div>



        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Goocampus CV Generator  ©2023</Footer>
    </Layout>
  )


}

export default ConsumerDashboard;
