const QuestionTypes = [
    {
        'type': 'text',
        'label': 'Text',
    },
    {
        'type': 'number',
        'label': 'Number',
    },
    {
        'type': 'email',
        'label': 'Email',
    },
    {
        'type': 'date',
        'label': 'Date',
    },
    {
        'type': 'datetime',
        'label': 'Date Time',
    },
    {
        'type': 'select',
        'label': 'Select',
    },
    {
        'type': 'select-multiple',
        'label': 'Select Multiple',
    },
    // {
    //     'type': 'switch',
    //     'label': 'Switch',
    // },
    {
        'type': 'checkbox',
        'label': 'Checkbox',
    },
    {
        'type': 'richtext',
        'label': 'Rich Text',
    },
]
module.exports = { QuestionTypes };