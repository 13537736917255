import React, { useState, useEffect } from 'react';
import { Table, Button, Layout, Modal, Form, notification, Select, Spin } from 'antd';
import { useUser } from './UserContext';
import HeaderComponent from './HeaderComponent';
import { useNavigate } from 'react-router-dom';
import { fetchWrapper } from './fetchWrapper.js';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'; 
const { Option } = Select;

const ResumeList = () => {
    const navigate = useNavigate();
    const { logout } = useUser();
    const [resumeFormats, setResumeFormats] = useState([]);
    const [plabs, setPlabs] = useState([]);
    const [isModalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [formData, setFormData] = useState({ resumeId: '', assignedPlabId: '' });
    const [loadingRows, setLoadingRows] = useState([]);
    useEffect(() => {
        const fetchPlabs = async () => {
            try {
                const response = await fetchWrapper('/setup/get-plabList', logout, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json();
                if (data.status === 1) {
                    setPlabs(data.plabList);
                }
            } catch (error) {
                console.error('Error fetching PLABs:', error);
            }
        };
    
        const fetchResumeFormats = async () => {
            try {
                const response = await fetchWrapper('/setup/get-resume-formats', logout, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json();
                if (data.status === 1) {
                    setResumeFormats(data.resumes);
                }
            } catch (error) {
                console.error('Error fetching resume formats:', error);
            }
        };
    
        // Use Promise.all to fetch both PLABs and resume formats in parallel
        Promise.all([fetchPlabs(), fetchResumeFormats()])
            .then(() => {
                // Both requests have completed
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [logout]);
    
    const handleEdit = (resumeId) => {
        // Redirect to the Edit Resume page with the resumeId as a parameter
        window.location.href = `/addEditResumeFormat?resumeId=${resumeId}`;

    };

    // Handle Delete Resume
    const handleDelete = async (resumeId) => {
        const confirmation = window.confirm('Are you sure you want to delete this resume?');
        if (confirmation) {
            try {
                const response = await fetchWrapper(`/setup/delete-resumeformat`, logout, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({resumeId }),
                });
                const data = await response.json();
                if (data.status === 1) {
                    notification.success({ message: 'Resume deleted successfully!' });
                    // Remove the deleted resume from the state
                    setResumeFormats(prevResumeFormats =>
                        prevResumeFormats.filter(resume => resume._id !== resumeId)
                    );
                } else {
                    notification.error({ message: 'Error deleting resume. Try again later.' });
                }
            } catch (error) {
                notification.error({ message: 'Error deleting resume. Try again later.' });
            }
        }
    };

    const handleAddResumeFormat = () =>{
        window.location.href = `/addEditResumeFormat`;
    }
    // const handleOpenModal = (resumeId) => {
    //     setFormData({ resumeId, assignedPlabId: '' });
    //     setModalVisible(true);
    // };

    // const handleCloseModal = () => {
    //     setModalVisible(false);
    //     setFormData({ resumeId: '', assignedPlabId: '' });
    // };

    const handleAssignPLAB = async (resumeId,plab) => {
        setLoadingRows([...loadingRows, resumeId]);
        try {
            const response = await fetchWrapper('/setup/assign-plab', logout, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({"resumeId":resumeId,"plab":plab})
            });
            const data = await response.json();
            if (data.status === 1) {
                notification.success({ message: 'PLAB assigned successfully!' });
                // handleCloseModal();

                // // Update the state with the assigned PLAB immediately
                setResumeFormats(prevResumeFormats => {
                    return prevResumeFormats.map(resume => {
                        if (resume._id === resumeId) {
                            return { ...resume, plab: plab };
                        }
                        return resume;
                    });
                });
            } else {
                notification.error({ message: data.message });
            }
            setLoadingRows(prevLoadingRows => prevLoadingRows.filter(id => id !== resumeId));
        } catch (error) {
            notification.error({ message: 'Error assigning PLAB. Try again later.' });
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: 'Resume Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Assigned PLAB',
            dataIndex: 'plab',
            key: 'plab',
            render: (plab, record) => (
                loadingRows.includes(record._id) ? 
                <Spin size="small" /> :
                <Select 
                    value={plabs.find(plabDoc => plabDoc._id === plab) ? `${plabs.find(plabDoc => plabDoc._id === plab).plabFormat} - ${plabs.find(plabDoc => plabDoc._id === plab).country}` : "unassigned"}
                    onChange={(selectedPlabId) => handleAssignPLAB(record._id, selectedPlabId)}>
                    {plabs.map(plab => (
                        <Option key={plab._id} value={plab._id}>{plab.plabFormat + " - " + plab.country}</Option>
                    ))}
                </Select>
            )
            // render: (plab, record) => (
            //     <div>
            //         {plab ? (
            //             <span>
            //                 {plabs.find(plabDoc => plabDoc._id === plab) ? `${plabs.find(plabDoc => plabDoc._id === plab).plabFormat} - ${plabs.find(plabDoc => plabDoc._id === plab).country}` : "-"}
            //             </span>
            //         ) : (
            //             <Button type="primary" onClick={() => handleOpenModal(record._id)}>Assign PLAB</Button>
            //         )}
            //     </div>
            // ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div>
                    <Button
                        type="primary"
                        icon={<EditOutlined />} // Set Edit icon
                        onClick={() => handleEdit(record._id)}
                    >
                        Edit
                    </Button>
                    <Button
                        type="danger"
                        icon={<DeleteOutlined />} // Set Delete icon
                        onClick={() => handleDelete(record._id)}
                        style={{ marginLeft: '10px' }}
                    >
                        Delete
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <HeaderComponent logout={logout} />
            <div style={{ padding: '20px', marginTop: "60px" }}>
            <div style={{ textAlign: 'end' }}>
             <Button type="primary" onClick={() => handleAddResumeFormat()}>Add New</Button>
        </div>

           
                <h1>Resume List</h1>
                <Table
                    dataSource={resumeFormats}
                    columns={columns}
                    pagination={false}
                    style={{ marginTop: '10px' }}
                />
                {/* Assign PLAB Modal */}
                {/* <Modal
                    title="Assign PLAB"
                    visible={isModalVisible}
                    onCancel={handleCloseModal}
                    footer={[
                        <Button key="back" onClick={handleCloseModal}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={handleAssignPLAB}>
                            Assign
                        </Button>
                    ]}
                >
                    <Form layout="vertical">
                        <Form.Item label="Select PLAB">
                            <Select
                                name="assignedPlabId"
                                value={formData.assignedPlabId}
                                onChange={(value) => setFormData({ ...formData, assignedPlabId: value })}
                            >
                                <Select.Option value="">Select a PLAB</Select.Option>
                                {plabs.map((plab) => (
                                    <Select.Option key={plab._id} value={plab._id}>
                                        {plab.country} - {plab.plabFormat}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal> */}
            </div>
        </Layout>
    );
};

export default ResumeList;
