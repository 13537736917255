// HeaderComponent.js

import React from 'react';
import { Menu, Layout } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { HomeOutlined, UserOutlined, LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import LogoImage from './images/logo.jpeg';

const { SubMenu } = Menu;
const { Header } = Layout;

const HeaderComponent = ({ logout }) => {
    const navigate = useNavigate();
    const location = useLocation(); // Get the current location
    const role = localStorage.getItem('userRole');

    // Function to determine the selected key based on the current location
    const getSelectedKey = () => {
        const pathMap = {
            '/admin-dashboard': 'home',
            '/consumer-dashboard': 'home',
            '/admin-profile': 'profile',
            '/consumer-profile': 'profile',
            '/resumeList': 'resumeFormat',
            '/setup-plab': 'placesOfAdmission',
            // Add other paths and their corresponding keys here
        };
        return pathMap[location.pathname] || 'home'; // Default to 'home' if path is not mapped
    };

    return (
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
            <div className="logo" style={{ float: 'left', color: 'white', fontSize: '18px', marginRight: '20px' }}>
                GooCampus CV Generator
            </div>

            <div className="logoImg" style={{ float: 'right', marginRight: '20px' }}>
            <img src={LogoImage} alt="Logo" style={{ maxWidth: '70px', height: 'auto' }} />
               
            </div>
            <Menu theme="dark" mode="horizontal" selectedKeys={[getSelectedKey()]}>
                <Menu.Item key="home" onClick={() => navigate(role === '2' ? "/admin-dashboard" : "/consumer-dashboard")} icon={<HomeOutlined />}>
                    Home
                </Menu.Item>
                {role === '2' && (
                    <Menu.Item key="profile" onClick={() => navigate(role === '2' ? "/admin-profile" : "/consumer-profile")} icon={<UserOutlined />}>
                        Profile

                    </Menu.Item>
                )}
                {role === '2' && (
                    <SubMenu key="setup" icon={<SettingOutlined />} title="Setup">
                        <Menu.Item key="resumeFormat" onClick={() => navigate("/resumeList")}>Resume Format</Menu.Item>
                        <Menu.Item key="placesOfAdmission" onClick={() => navigate("/setup-plab")}>Plab</Menu.Item>
                    </SubMenu>
                )}
                {role === '2' && (
                    <Menu.Item key="logout" style={{ float: 'right' }} onClick={logout} icon={<LogoutOutlined />}>
                        Logout
                    </Menu.Item>
                )}
            </Menu>
        </Header>
    );
};

export default HeaderComponent;
