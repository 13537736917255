import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, Divider, notification, Layout, Modal } from 'antd';
import { useUser } from './UserContext';
import HeaderComponent from './HeaderComponent';
import { fetchWrapper } from './fetchWrapper.js';


const UserProfile = ({ userId }) => {
    const { logout } = useUser();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
    const [passwords, setPasswords] = useState({ currentPassword: '', newPassword: '', confirmNewPassword: '' });

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await fetchWrapper('/user/details',logout, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                  //  credentials: 'include' // This is necessary to send cookies along with the request
                });
                const data = await response.json();
                setUser(data);
            } catch (error) {
                notification.error({ message: 'Error fetching user details.' });
            } finally {
                setLoading(false);
            }
        };
    
        fetchUserDetails();
    }, []);
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUser(prev => ({ ...prev, [name]: value }));
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswords(prev => ({ ...prev, [name]: value }));
    };

    const handleProfilePhotoChange = (event) => {
        const file = event.target.files[0]; // Get the first file
    
        if (file) {
            setUser(prevUser => ({
                ...prevUser,
                profilePhoto: file // Update the user state with the selected file
            }));
        }
    };



    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission
        setLoading(true);
    
        const formData = new FormData();
    
        // Append user data to formData
        // Assuming 'user' is an object containing user data
        formData.append('firstName', user.firstName || "");
        formData.append('lastName', user.lastName || "");
        formData.append('username', user.username);
        formData.append('phoneNumber', user.phoneNumber || "");
    
        // Append the profile photo if it exists
        // Check if the file input is not empty and user.profilePhoto is a File object
        if (user.profilePhoto && user.profilePhoto instanceof File) {
            formData.append('profilePhoto', user.profilePhoto);
        }
    
        try {
            const response = await fetchWrapper('/user/update', logout, {
                method: 'POST',
                body: formData, // Sending the formData
                // Do not set Content-Type header; let the browser set it
            });
    
            const data = await response.json();
            if (data.success) {
                notification.success({ message: 'Profile updated successfully!' });
                setIsEditing(false);
            } else {
                notification.error({ message: 'Error updating profile.' });
            }
        } catch (error) {
            notification.error({ message: 'Error updating profile. Please try again.' });
        } finally {
            setLoading(false);
        }
    };
    

    

    const handlePasswordUpdate = async () => {
        // Add your logic to check if newPassword and confirmNewPassword match

        setLoading(true);
        try {
            const response = await fetchWrapper('/user/change-password',logout, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(passwords)
            });
            const data = await response.json();
            if (data.success) {
                notification.success({ message: 'Password updated successfully!' });
                setIsPasswordModalVisible(false);
            } else {
                notification.error({ message: data.message });
            }
        } catch (error) {
            notification.error({ message: 'Error updating password. Please try again.' });
        } finally {
            setLoading(false);
        }
    };

    return (
        
        <Layout style={{ minHeight: '100vh' }}>
    <HeaderComponent logout={logout} />

    <div style={{ padding: '20px', marginTop: '80px' }}>
        <Card title="User Profile" style={{ maxWidth: '600px', margin: '40px auto', padding: '20px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                
                <div style={formGroupStyle}>
                    <label style={labelStyle}>First Name</label>
                    <input
                        type="text"
                        name="firstName"
                        value={user.firstName}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        style={inputStyle}
                    />
                </div>

                <div style={formGroupStyle}>
                    <label style={labelStyle}>Last Name</label>
                    <input
                        type="text"
                        name="lastName"
                        value={user.lastName}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        style={inputStyle}
                    />
                </div>

                <div style={formGroupStyle}>
                    <label style={labelStyle}>Username</label>
                    <input
                        type="text"
                        name="username"
                        value={user.username}
                        onChange={handleInputChange}
                        disabled
                        style={inputStyle}
                    />
                </div>

                <div style={formGroupStyle}>
                    <label style={labelStyle}>Profile Photo</label>
                    <input
                        type="file"
                        name="profilePhoto"
                        onChange={handleProfilePhotoChange}
                        disabled={!isEditing}
                        style={inputStyle}
                    />
                            {user.profilePhoto && (
                                <img
                                    src={user.profilePhoto}
                                    alt="Profile"
                                    style={{ width: '100px', height: '100px', marginTop: '10px' }}
                                />
                            )}
                </div>

                <div style={formGroupStyle}>
                    <label style={labelStyle}>Phone Number</label>
                    <input
                        type="number"
                        name="phoneNumber"
                        value={user.phoneNumber}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        style={inputStyle}
                    />
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                    {isEditing ? (
                        <React.Fragment>
                            <button type="submit" style={buttonStyle}>Save</button>
                            <button type="button" onClick={() => setIsEditing(false)} style={buttonStyle}>Cancel</button>
                        </React.Fragment>
                    ) : ""}
                </div>
            </form>
            <Divider />
            {!isEditing ? (
            <button type="button" onClick={() => setIsEditing(true)} style={buttonStyle}>Edit Profile</button>) : ""}
                    <Button
                        type="button"
                        style={{
                            color: "#FFF",
                            padding: "8 15",
                            border: "none",
                            borderRadius: 4,
                            backgroundColor: "#fd7213",
                            marginLeft: 10,
                          }}
                        onClick={() => setIsPasswordModalVisible(true)}>
                        Change Password
                    </Button>

            <Modal
                title="Change Password"
                visible={isPasswordModalVisible}
                onCancel={() => setIsPasswordModalVisible(false)}
                onOk={handlePasswordUpdate}
                style={{ maxWidth: '500px', margin: '0 auto' }}
            >
                <form style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                    <div style={formGroupStyle}>
                        <label style={labelStyle}>Current Password</label>
                        <input type="password" name="currentPassword" onChange={handlePasswordChange} style={inputStyle} />
                    </div>
                    <div style={formGroupStyle}>
                        <label style={labelStyle}>New Password</label>
                        <input type="password" name="newPassword" onChange={handlePasswordChange} style={inputStyle} />
                    </div>
                    <div style={formGroupStyle}>
                        <label style={labelStyle}>Confirm New Password</label>
                        <input type="password" name="confirmNewPassword" onChange={handlePasswordChange} style={inputStyle} />
                    </div>
                </form>
            </Modal>

        </Card>
    </div>
</Layout>
                             
    );
};

const formGroupStyle = {
    marginBottom: '15px',
};

const labelStyle = {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
};

const inputStyle = {
    width: '100%',
    padding: '8px 15px',
    border: '1px solid #ccc',
    borderRadius: '4px',
};

const buttonStyle = {
    padding: '8px 15px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#007bff',
    color: 'white',
    cursor: 'pointer',
};


export default UserProfile;
