
import "./ResetPassword.css"; 

import React, { useState } from "react";
import { Form, Input, Button, message, Spin, Typography } from "antd";
import {  Link, useLocation } from 'react-router-dom';
const { Title, Text } = Typography;


function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const key = params.get("key");

  const handleResetPassword = async () => {
    try {
      setLoading(true);
      const response = await fetch(process.env.REACT_APP_BASE_URL+'/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token,key, newPassword }),
      });

      const data = await response.json();

      if (response.ok) {
        message.success(data.message);
        // Redirect to the login page or any other page as needed
      } else {
        message.error(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('An error occurred while resetting the password.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="reset-password-container">
      <Title level={2}>Welcome!</Title>
      <Text>Please set your new password below.</Text>
      <Form className="reset-password-form">
        <Form.Item label="New Password">
          <Input.Password
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Confirm Password">
          <Input.Password
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={handleResetPassword}
            disabled={!newPassword || newPassword !== confirmPassword || loading}
          >
            {loading ? <Spin /> : "Reset Password"}
          </Button>
        </Form.Item>
        <Form.Item>
          <Link to="/login">Back to Login</Link>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ResetPassword;
