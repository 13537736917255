import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { UserProvider, useUser } from './UserContext';
import Login from './Login';
import AdminDashboard from './AdminDashboard';
import Register from './Register';
import Plab from './Plab'
import ResumeFormat from './ResumeFormat'
import ConsumerDashboard from './ConsumerDashboard';
import ConsumerFillResume from './ConsumerFillResume';
import ViewResume from './ViewResume';
import * as serviceWorker from './serviceWorker';
import UserProfile from './UserProfile';
import ResetPassword from './ResetPassword';
import ResumeList from './ResumeList';

const ProtectedRouteComponent = ({ allowedRole, protectedComponent }) => {
  const { userRole } = useUser();
  
  if (userRole === allowedRole) {
    return React.cloneElement(protectedComponent);
  }

  return <Navigate to="/login" />;
};

const RedirectToDashboard = () => {
  const { userRole } = useUser();
  
  switch (userRole) {
    case '1':
      return <Navigate to="/consumer" />;
    case '2':
      return <Navigate to="/admin" />;
    default:
      return <Login />;
  }
};

const App = () => {
  return (
    <Router>
      <Routes>
     
      <Route path="/register" element={<Register />} />
        <Route path="/login" element={<RedirectToDashboard />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/admin" element={<ProtectedRouteComponent allowedRole="2" protectedComponent={<AdminDashboard />} />} />
        <Route path="/resumeList" element={<ProtectedRouteComponent allowedRole="2" protectedComponent={<ResumeList />} />} />
        <Route path="/view-resume-pdf" element={<ProtectedRouteComponent allowedRole="1" protectedComponent={<ViewResume />} />} />
        <Route path="/view-resume-pdfAdmin" element={<ProtectedRouteComponent allowedRole="2" protectedComponent={<ViewResume />} />} />
        <Route path="/setup-plab" element={<ProtectedRouteComponent allowedRole="2" protectedComponent={<Plab />} />} />
        <Route path="/addEditResumeFormat" element={<ProtectedRouteComponent allowedRole="2" protectedComponent={<ResumeFormat />} />} />
        <Route path="/admin-profile" element={<ProtectedRouteComponent allowedRole="2" protectedComponent={<UserProfile />} />} />
        <Route path="/consumer-profile" element={<ProtectedRouteComponent allowedRole="1" protectedComponent={<UserProfile />} />} />
        <Route path="/consumer" element={<ProtectedRouteComponent allowedRole="1" protectedComponent={<ConsumerDashboard />} />} />
        <Route path="/addEditResume" element={<ProtectedRouteComponent allowedRole="1" protectedComponent={<ConsumerFillResume />} />} />
        <Route path="/addEditResumeAdmin" element={<ProtectedRouteComponent allowedRole="2" protectedComponent={<ConsumerFillResume />} />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <App />
    </UserProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
