import React, { useState, useEffect } from 'react';
import { Table, Menu, Button, Layout, Modal, Form, notification,Input } from 'antd';
import { useUser } from './UserContext';

import HeaderComponent from './HeaderComponent';
import { useNavigate } from 'react-router-dom';
import { fetchWrapper } from './fetchWrapper.js';

const Plab = () => {
    const navigate = useNavigate();
    const { logout } = useUser();
    const [plabs, setPlabs] = useState([]);
    const [isModalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ country: '', plabFormat: '' });
    const [currentEditingId, setCurrentEditingId] = useState(null);

    useEffect(() => {
        const fetchPlabs = async () => {
            try {
                const response = await fetchWrapper('/setup/get-plabList',logout, {
                    method: 'GET',
                    // credentials: 'include',  // Include this line
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json(); 
                if (data.status === 1) {
                    console.log("plab",data.plabList);
                    setPlabs(data.plabList);
                }
            } catch (error) {
                console.error('Error fetching plabs:', error);
            }
        };

        fetchPlabs();
    }, []);

    const handleOpenModal = (plab = {}) => {
        setFormData({
            country: plab.country || '',
            plabFormat: plab.plabFormat || ''
        });
        setCurrentEditingId(plab._id || null);
        setModalVisible(true);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
        setFormData({ country: '', plabFormat: '' });
        setCurrentEditingId(null);
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            const response = await fetchWrapper('/setup/add-edit-plab',logout, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    ...formData,
                    _id: currentEditingId
                })
            });
            const data = await response.json();
            if (data.status === 1) {
                notification.success({ message: currentEditingId ? 'Edited successfully!' : 'Added successfully!' });
                handleCloseModal();
            } else {
                notification.error({ message: 'Error saving data. Try again later.' });
            }
        } catch (error) {
            notification.error({ message: 'Error saving data. Try again later.' });
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        const confirmation = window.confirm('Are you sure you want to delete this record?');
        if (confirmation) {
            try {
                const response = await fetchWrapper(`/setup/delete-plab`,logout, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ _id: id })
                });
                const data = await response.json();
                if (data.status === 1) {
                    notification.success({ message: 'Deleted successfully!' });
                    setPlabs(prevPlabs => prevPlabs.filter(plab => plab._id !== id));
                } else {
                    notification.error({ message: 'Error deleting data. Try again later.' });
                }
            } catch (error) {
                notification.error({ message: 'Error deleting data. Try again later.' });
            }
        }
    };

    const columns = [
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
        },
        {
            title: 'Plab Format',
            dataIndex: 'plabFormat',
            key: 'plabFormat',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, plab) => (
                <div>
                    <Button type="primary" onClick={() => handleOpenModal(plab)}>Edit</Button>
                    <Button type="danger" onClick={() => handleDelete(plab._id)} style={{ marginLeft: '10px' }}>Delete</Button>
                </div>
            ),
        },
    ];

    return (
        <Layout style={{ minHeight: '100vh' }}>
             <HeaderComponent logout={logout} />
            <div style={{ padding: '20px', marginTop:"60px"}}>
                <h1>Plab</h1>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px' }}>
                    <Button type="primary" onClick={() => handleOpenModal()}>Add New</Button>
                </div>
                <Table 
                    dataSource={plabs} 
                    columns={columns} 
                    pagination={false} 
                    style={{ marginTop: '10px' }}
                />
                {/* Add/Edit Plab Modal */}
                <Modal
                    title={currentEditingId ? "Edit Plab" : "Add New Plab"}
                    visible={isModalVisible}
                    onCancel={handleCloseModal}
                    footer={[
                        <Button key="back" onClick={handleCloseModal}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={handleSave}>
                            Save
                        </Button>
                    ]}
                >
                    <Form layout="vertical">
                        <Form.Item label="Country">
                            <Input name="country" value={formData.country} onChange={handleFormChange} />
                        </Form.Item>
                        <Form.Item label="Plab Format">
                            <Input name="plabFormat" value={formData.plabFormat} onChange={handleFormChange} />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </Layout>
    );
};

export default Plab;
