import React, { useState, useEffect } from 'react';
import 'antd/dist/reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Layout, Button, FloatButton, Space, Card, Steps, theme, notification } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { fetchWrapper } from './fetchWrapper.js';

import { useUser } from './UserContext';
import './ViewResume.css';
import html2pdf from 'html2pdf.js';

// const ResumeData = {
//     "_id_name": "Dr. Deepannita Roy Chowdhury",
//     "_id_address": `
//     KMDA Nagar, Phase-II
//     <div>Barrackpore, West Bengal, India, Kolkata-700121.</div>`,
//     "_id_contactNo": "+91 7016938045",
//     "_id_contactEmail": "rc.deepannita@gmail.com",
//     _id_qualification: `<div> <b>MBBS</b> <div>D. Y. Patil University, Kolhapur, India.</div> </div>`,
//     _id_state_registration: `<div> <b>2022/09/7984</b><span> (Maharashtra Medical Council)</span> </div>`,
//     _id_lang_test:` <div> IELTS Overall Score: <b>8</b> </div>`,
//     _id_gmc_status:`<div> GMC Number - <b>7927885</b> (Passed PLAB & waiting for full license to practice) </div>`,
//     _id_other_rel_info:`<div> <ul> <li>Can start work straight away, if selected</li> <li>Good at medical procedures and handling patients concerns.</li> </ul> </div>`,
//     _id_personal_statement:`<div> My desire to practice Obstetrics and Gynecology has grown exponentially ever since my exposure to the specialty. I'm intrigued by the diversity of this field, as patient care starts right from adolescence, all the way until their retirement and gives me the opportunity to practice preventive medicine and provide continuity of care. </div> <br /> <div> In this specialty, it's a privilege that patients feel comfortable enough to share their intimate personal information and the challenges they face including family planning, childbirth and menopause. The most rewarding part of this experience is watching patients on a regular basis for prenatal care and getting to know them and their family </div>`,
//     _id_observership: `<ul> <li>Observership at Royton Health & Wellbeing Center, Oldham, Manchester</li> </ul>`,
//     _id_course: `Participated in Med Ace 2019 Pre- ConferenceWorkshop`,
//     _id_course_1: `Completed a course on Electronic Fetal Monitoring (eFM) by e-integrity`,
//     _id_course_2: `One-day certificate course on Organ Donation by Multi Organ Harvesting Aid NetworkFoundation`,
//     _id_course_3: `Completed Teaching Methodology Course by Feature Learn`,
//     _id_accomplish: `Ambassador at Multi Organ Harvesting Aid Network Foundation`,
//     _id_accomplish_1: `Oral Presentation atthe International Medical Conference 2021, virtually hosted by BAPIO Training Academy, UK `,
//     _id_accomplish_2: `Participated in Debate competition “Sensitization to Bio-Ethics” for World Bio-Ethics Day`,
//     _id_accomplish_3: `Was part of Organ Donation Awareness Campaign organized by D.Y Patil Medical College`,
//     _id_accomplish_4: `Participated in National Level Second MBBS Alliance for Research and Training`,
//     _id_accomplish_5: `Worked as Fundraising Intern for 45 days - Free Pads for India`,
//     _id_accomplish_6: `Volunteered in Post Flood Family Health SurveyActivity`,
//     _id_research: `Co-author in a Research paper which is in progress, titled: - “The prevalence of thyroid disorder in pregnancy”`,
//     _id_teaching:`Conducted IELTS sessions for medical students / doctors`,
//     _id_teaching_1:`Conducted PLAB training sessions for medical students / doctors`,
//     _id_teaching_2:`Conducted subjective lectures for juniors in university`,
//     _id_leadership: `Member of student council in D.Y. Patil Medical College`,
//     _id_leadership_1: `Particularly enthusiastic about singing and drawing. Having a Degree in Bachelor of Fine Arts and also participated in several competitions for the same.`,
//     _id_leadership_2: `Participated in several inter-school and inter-collegiate debate competitions`,
//     _id_leadership_3: `Languages Spoken - English, Hindi & Bengali`,
// }

const ResumeDataConfig = {
    "name": "6539082f406a5dc3f1c0d613",
    "address": "655c7d37da87599fee1deca7",
    "contactNo": "655c7d37da87599fee1deca6",
    "contactEmail": "655c7d37da87599fee1deca5",
    "qualification": "655c7d37da87599fee1deca8",
    state_registration: "_id_state_registration",
    lang_test: "6578541667b82624d1c2b94a",
    lang_test1:"6578541667b82624d1c2b94b",
    gmc_status: '6578541667b82624d1c2b94c',
    other_rel_info: '655db215da87599fee1deea4',
    personal_statement: '6578541667b82624d1c2b94d',
    observership: '_id_observership',
    course: '65390b58a22439c556803a28',
    accomplish: '65390b58a22439c556803a2a',
    research: '654b727162f3e6da7f42a882',
    teaching: '65390b58a22439c556803a2e',
    leadership: '65390b58a22439c556803a30',
    intern_start_date: '655c7d37da87599fee1decad',
    intern_end_date: '655c7d37da87599fee1decae',
    intern_hospital: '655c7d37da87599fee1decaf',
    intern_college: '655c7d37da87599fee1decb0',
    intern_work: '655c7d37da87599fee1decb1',
    employee_start_date: '655db215da87599fee1dee9f',
    employee_end_date: '656f1548edeb8fb40ce9858e',
    employee_job_designation: '656f1548edeb8fb40ce9858f',
    employee_hospital: '656f1548edeb8fb40ce98590',
    employee_work: '656f1548edeb8fb40ce98591'
}

async function fetchAndSetResumeFormat(resumeId, upgrade, userId, plabId, logout) {
    try {

        const response = await fetchWrapper('/user/add-edit-resume', logout, {
            method: 'POST', // Change to GET if you're retrieving data
            headers: {
                'Content-Type': 'application/json',
            },

            body: JSON.stringify({ resumeId, upgrade, userId, plabId })
            // You can add more headers as needed, such as authorization headers
            // credentials: 'include' // This is necessary to send cookies along with the request
        });

        if (response.ok) {
            const tempResponse = await response.json();

            return tempResponse;
        } else {
            // Handle HTTP errors, if any
            const errorMessage = await response.text();
            throw new Error(errorMessage);
        }
    } catch (error) {
        // Handle network errors and exceptions
        console.error('Error fetching resume details:', error);
        notification.error({ message: 'Error fetching resume details.' });
    }
}







const ViewResumeComponent = () => {
    const [ResumeData, SetResumeData] = useState({});
    const [buttonsVisible, setButtonsVisible] = useState(true);
    const [ResumeQuestion, SetResumeQuestion] = useState({});
    const { logout } = useUser()
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const resumeId = params.get("resumeId");
    const upgrade = params.get("upgrade") || "false";
    let userId = params.get("userId") || null;
    let plabId = params.get("plabId") || null;
    let triggerPrint = params.get("triggerPrint") || null;


    const handleDownload = () => {
      

        // const element = document.body; // Change this to the specific element you want to convert
        // html2pdf(element, {
        //     pagebreak: {before: '.newPage', avoid: ['h2', 'h3', 'h4', '.field']},
        //     margin: 10, // Set minimum margin for printing
        //     // pagebreak: { avoid: "tr", mode: "css", before: "#nextpage1", after: "1cm" },
        // });
    
        //showButtons(); 


        //     const element = document.body; // Change this to the specific element you want to convert
        //     html2pdf(element);
        //    //
    };

    const handlePrint = () => {
        window.print();
    } 

    const getData = (ref, ind) => {
       
        console.log("resumeData",ResumeData)
        let key = `${ResumeDataConfig[ref]}`
        if (ind) {
            key = `${key}_${ind}`;
        }
        let value = ResumeData[key];
    
       
        return value
    }

    const getQuestionLabel = (ref, ind) => {
        let key = `${ResumeDataConfig[ref]}`
        if (ind) {
            key = `${key}_${ind}`;
        }
        return ResumeQuestion[key]
    }
    const checkAvailData = (ref, ind) => {
        let key = `${ResumeDataConfig[ref]}`
        if (ind) {
            key = `${key}_${ind}`;
        }
        return ResumeData.hasOwnProperty(key)
    }

    useEffect(() => {
        document.body.classList.add("viewResumePage");
        async function fetchResumeData() {
            try {
                const data = await fetchAndSetResumeFormat(resumeId, upgrade, userId, plabId, logout);
                try {
                    let hashData = {};
                    let questionData = {};
                    data.resume.resumeAnswer.forEach((thisSection) => {
                        thisSection.questions.forEach((thisQues) => {
                            let val = thisQues?.answer?.value;
                            if (Array.isArray(val)) {
                                val = val.join()
                            }
                            hashData[thisQues.questionId] = val;
                        })
                    })
                    SetResumeData(hashData)

                    data.resumeFormat.sections.forEach((thisSection) => {
                        thisSection.questions.forEach((thisQues) => {
                            let questionLabel = thisQues?.label;
                            questionData[thisQues._id] = questionLabel;
                        })
                    })
                    SetResumeQuestion(questionData)

                    if(triggerPrint){
                        handleDownload();
                    }
                }
                catch (e) {

                }

                // setResume(data.resume);

            } catch (error) {
                console.error('Error fetching resume format:', error);
                notification.error({ message: 'Error fetching resume format.' });
            }
        }
        fetchResumeData();
    }, []);
    return (
        <div className="pageContainer mt-5">
            <div className="container">
            {buttonsVisible && (
                <div className="print-download-buttons d-flex justify-content-end my-2 me-1">
                    <button className="btn no-print btn-primary" data-html2canvas-ignore="true" style={{ fontFamily: 'Times New Roman' }} onClick={handlePrint}>
  Print/Download Pdf
</button>

                    {/* <button className='btn no-print btn-secondary ms-2' data-html2canvas-ignore="true" onClick={handleDownload}>Download</button> */}
                </div>
            )}
                <div className="personalInfo resume-segment">

                    {getData("name") && getData("name") !== "" && (
                        <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }} className="name">{getData("name")}</div>
                    )
                    }
                    {
                        getData("address") && getData("address") !== "" && (
                            <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }} className="address" dangerouslySetInnerHTML={{ __html: `${getQuestionLabel("address")}: ${getData("address")}` }}></div>
                        )
                    }

                    {getData("contactNo") && getData("contactNo") !== "" && (
                        <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }} className="contactNo">{getQuestionLabel("contactNo")}: {getData("contactNo")}</div>
                    )
                    }
                    {getData("contactEmail") && getData("contactEmail") !== "" && (
                        <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }} className="contactEmail">{getQuestionLabel("contactEmail")}: {getData("contactEmail")}</div>
                    )
                    }


                </div>
                <div className="qualificationInfo resume-segment">
                    <div className="column-container">
                        {getData("qualification") && getData("qualification") !== "" && (
                            <div className="row">
                                <div className="column label-column">{getQuestionLabel("qualification")}</div>
                                <div className="column content-column" style={{ borderTop: `1px solid #b8cce4` }} >
                                    <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }} dangerouslySetInnerHTML={{ __html: getData("qualification") }}></div>
                                </div>
                            </div>
                        )}
                        {getData("state_registration") && getData("state_registration") !== "" && (
                            <div className="row">
                                <div className="column label-column">{getQuestionLabel("state_registration")}</div>
                                <div className="column  content-column" dangerouslySetInnerHTML={{ __html: getData("state_registration") }}></div>
                            </div>
                        )}
                        {getData("lang_test") && getData("lang_test") !== "" && (
                            <div className="row">
                                <div className="column label-column">{getQuestionLabel("lang_test")}</div>
                                <div className="column content-column">
                                    <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }} dangerouslySetInnerHTML={{ __html: getData("lang_test") }}></div>
                                </div>
                            </div>
                        )}

                        {getData("lang_test1") && getData("lang_test1") !== "" && (!getData("lang_test") ||  getData("lang_test") === "") && (
                            <div className="row">
                                <div className="column label-column">{getQuestionLabel("lang_test1")}</div>
                                <div className="column content-column">
                                    <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }} dangerouslySetInnerHTML={{ __html: getData("lang_test1") }}></div>
                                </div>
                            </div>
                        )}
                       
                        {getData("gmc_status") && getData("gmc_status") !== "" && (
                            <div className="row">
                                <div className="column label-column">{getQuestionLabel("gmc_status")}</div>
                                <div className="column content-column">
                                    <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }} dangerouslySetInnerHTML={{ __html: getData("gmc_status") }}></div>
                                </div>
                            </div>
                        )}
                        {getData("other_rel_info") && getData("other_rel_info") !== "" && (
                            <div className="row">
                                <div className="column label-column">{getQuestionLabel("other_rel_info")}</div>
                                <div className="column content-column">
                                    <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }} dangerouslySetInnerHTML={{ __html: getData("other_rel_info") }}></div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {getData("personal_statement") && getData("personal_statement") !== "" && (
                    <div className="personalStatement resume-segment">
                        <div className="cardBrand">

                            <div className="cardHeader">{getQuestionLabel("personal_statement")}</div>
                            <div className="cardBody text-justify" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }} dangerouslySetInnerHTML={{ __html: getData("personal_statement") }}></div>
                        </div>
                    </div>
                )
                }
                {getData("observership") && getData("observership") !== "" && (
                    <div className="observership resume-segment">
                        <div className="cardBrand">
                            <div className="cardHeader">{getQuestionLabel("observership")}</div>
                            <div className="cardBody" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }} dangerouslySetInnerHTML={{ __html: getData("observership") }}></div>
                        </div>
                    </div>
                )
                }
                {getData('intern_start_date', 0) && getData('intern_start_date', 0) !== "" && (
                    <div className="employmentDetails resume-segment">
                        {
                            [...new Array(100)].filter((t, i) => {
                                return checkAvailData('intern_start_date', i)
                            }).map((t, i) => {
                                return (
                                    <div key={i} className="custom-table">
  <div className="header-row">
    <div className="header-cell bordered table-header">Internship Date</div>
    <div className="header-cell bordered table-header">{getQuestionLabel("intern_hospital")}</div>
    <div className="header-cell bordered table-header">{getQuestionLabel("intern_college")}</div>
  </div>
  <div className="table-row">
    <div className="table-cell bordered text-center">
      {getData('intern_start_date', i)} - {getData('intern_end_date', i) ? getData('intern_end_date', i) : "Current"}
    </div>
    <div  style={{ wordWrap: 'break-word', whiteSpace: 'normal' }} className="table-cell bordered text-center" dangerouslySetInnerHTML={{ __html: getData('intern_hospital', i) }}></div>
    <div  style={{ wordWrap: 'break-word', whiteSpace: 'normal' }} className="table-cell bordered text-center" dangerouslySetInnerHTML={{ __html: getData('intern_college', i) }}></div>
  </div>
  <div className="table-row">
    <div className="table-cell bordered" colSpan={3} style={{ padding: '0px' }}>
      <div className='departmentScopeTitle'>{getQuestionLabel('intern_work')}</div>
    </div>
  </div>
  <div className="table-row">
    <div className="table-cell bordered singleScope" colSpan={3}>
      <div  style={{ wordWrap: 'break-word', whiteSpace: 'normal',paddingLeft:'25px' }} dangerouslySetInnerHTML={{ __html: getData('intern_work', i) }}></div>
    </div>
  </div>
</div>


                                  
                                )
                            })
                        }
                    </div>

                )
                }
                {getData('employee_start_date', 0) && getData('employee_start_date', 0) !== "" && (
                    <div className="employmentDetails resume-segment">
                        {
                            [...new Array(100)].filter((t, i) => {
                                return checkAvailData('employee_start_date', i)
                            }).map((t, i) => {
                                return (
                                    // <table key={i} className="table table-bordered">
                                    //     <thead>
                                    //         <tr className="align-middle">
                                    //             <th>Employment Date</th>
                                    //             <th>{getQuestionLabel("employee_hospital")}</th>
                                    //             <th>{getQuestionLabel("employee_job_designation")}</th>
                                    //         </tr>
                                    //     </thead>
                                    //     <tbody>
                                    //         <tr>
                                    //             <td className="text-center">{getData('employee_start_date', i)} - {getData('employee_end_date', i) ? getData('employee_end_date', i) : "Current"}</td>
                                    //             <td className="text-center" dangerouslySetInnerHTML={{ __html: getData('employee_hospital', i) }}></td>
                                    //             <td className="text-center" dangerouslySetInnerHTML={{ __html: getData('employee_job_designation', i) }}></td>

                                    //         </tr>
                                    //         <tr>
                                    //             <td style={{ padding: '0px' }} colSpan={3}>
                                    //                 <div className='departmentScopeTitle'>{getQuestionLabel('employee_work')}</div>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan={3}>
                                    //                 <div className="singleScope" dangerouslySetInnerHTML={{ __html: getData('employee_work', i) }}></div>
                                    //             </td>
                                    //         </tr>
                                    //     </tbody>
                                    // </table>

                                    <div key={i} className="custom-table">
  <div className="header-row">
    <div className="header-cell bordered table-header">Employment Date</div>
    <div className="header-cell bordered table-header">{getQuestionLabel("employee_hospital")}</div>
    <div className="header-cell bordered table-header">{getQuestionLabel("employee_job_designation")}</div>
  </div>
  <div className="table-row">
    <div className="table-cell bordered text-center">
      {getData('employee_start_date', i)} - {getData('employee_end_date', i) ? getData('employee_end_date', i) : "Current"}
    </div>
    <div  style={{ wordWrap: 'break-word', whiteSpace: 'normal' }} className="table-cell bordered text-center" dangerouslySetInnerHTML={{ __html: getData('employee_hospital', i) }}></div>
    <div  style={{ wordWrap: 'break-word', whiteSpace: 'normal' }} className="table-cell bordered text-center" dangerouslySetInnerHTML={{ __html: getData('employee_job_designation', i) }}></div>
  </div>
  <div className="table-row">
    <div className="table-cell bordered" colSpan={3} style={{ padding: '0px' }}>
      <div className='departmentScopeTitle'>{getQuestionLabel('employee_work')}</div>
    </div>
  </div>
  <div className="table-row">
    <div className="table-cell bordered singleScope" colSpan={3}>
      <div  style={{ wordWrap: 'break-word', whiteSpace: 'normal', paddingLeft:'25px' }} dangerouslySetInnerHTML={{ __html: getData('employee_work', i) }}></div>
    </div>
  </div>
</div>
                                )
                            })
                        }
                    </div>

                )
                }
                {getData('course', 0) && getData('course', 0) !== "" && (
                    <div className="courses resume-segment">
                        <div className="cardBrand">
                            <div className="cardHeader">Courses</div>
                            <div className="listMultiple">
                                {
                                    [...new Array(100)]
                                        .filter((t, i) => checkAvailData('course', i) && hasVisibleContent(getData('course', i)))
                                        .map((t, i) => (
                                            <div key={i} className="singleItem" dangerouslySetInnerHTML={{ __html: getData('course', i) }}></div>
                                        ))
                                }



                            </div>
                        </div>
                    </div>
                )
                }
                {getData('accomplish', 0) && getData('accomplish', 0) !== "" && (
                    <div className="accomplishments resume-segment">
                        <div className="cardBrand">
                            <div className="cardHeader">Accomplishments & Volunteering works</div>
                            <div className="listMultiple">
                                {
                                    [...new Array(100)]
                                    .filter((t, i) => checkAvailData('accomplish', i) && hasVisibleContent(getData('accomplish', i)))
                                    .map((t, i) => (
                                        <div key={i} className="singleItem" dangerouslySetInnerHTML={{ __html: getData('accomplish', i) }}></div>
                                    ))

                                    
                                }
                            </div>
                        </div>
                    </div>
                )
                }
                {getData('research', 0) && getData('research', 0) !== "" && (
                    <div className="research resume-segment">
                        <div className="cardBrand">
                            <div className="cardHeader">Research & Publication</div>
                            <div className="listMultiple">
                            {
                                    [...new Array(100)]
                                    .filter((t, i) => checkAvailData('research', i) && hasVisibleContent(getData('research', i)))
                                    .map((t, i) => (
                                        <div key={i} className="singleItem" dangerouslySetInnerHTML={{ __html: getData('research', i) }}></div>
                                    ))

                                    
                                }
                                {/* {
                                    [...new Array(100)].filter((t, i) => {
                                        return checkAvailData('research', i)
                                    }).map((t, i) => {
                                        return (
                                            <div key={i} className="singleItem" dangerouslySetInnerHTML={{ __html: getData('research', i) }}></div>
                                        )
                                    })
                                } */}
                            </div>
                        </div>
                    </div>
                )
                }
                {getData('teaching', 0) && getData('teaching', 0) !== "" && (
                    <div className="teaching resume-segment">
                        <div className="cardBrand">
                            <div className="cardHeader">Teaching Experience & other information</div>
                            <div className="listMultiple">
                                {/* {
                                    [...new Array(100)].filter((t, i) => {
                                        return checkAvailData('teaching', i)
                                    }).map((t, i) => {
                                        return (
                                            <div key={i} className="singleItem" dangerouslySetInnerHTML={{ __html: getData('teaching', i) }}></div>
                                        )
                                    })
                                } */}
                                 {
                                    [...new Array(100)]
                                    .filter((t, i) => checkAvailData('teaching', i) && hasVisibleContent(getData('teaching', i)))
                                    .map((t, i) => (
                                        <div key={i} className="singleItem" dangerouslySetInnerHTML={{ __html: getData('teaching', i) }}></div>
                                    ))

                                    
                                }
                            </div>
                        </div>
                    </div>
                )
                }
                {getData('leadership', 0) && getData('leadership', 0) !== "" && (
                    <div className="leadership resume-segment">
                        <div className="cardBrand">
                            <div className="cardHeader">Leadership Skills & Extra-curricular activities</div>
                            <div className="listMultiple">
                                {/* {
                                    [...new Array(100)].filter((t, i) => {
                                        return checkAvailData('leadership', i)
                                    }).map((t, i) => {
                                        return (
                                            <div key={i} className="singleItem" dangerouslySetInnerHTML={{ __html: getData('leadership', i) }}></div>
                                        )
                                    })
                                } */}
                                {
                                    [...new Array(100)]
                                    .filter((t, i) => checkAvailData('leadership', i) && hasVisibleContent(getData('leadership', i)))
                                    .map((t, i) => (
                                        <div key={i} className="singleItem" dangerouslySetInnerHTML={{ __html: getData('leadership', i) }}></div>
                                    ))

                                    
                                }
                            </div>
                        </div>
                    </div>
                )
                }
                <div className="" style={{ padding: '10px' }}>

                </div>

            </div>
        </div>
    )
};

// Outside the JSX block, define the hasVisibleContent function
function hasVisibleContent(htmlString) {
    return htmlString && htmlString.replace(/<[^>]*>/g, '').trim() !== '';
}
export default ViewResumeComponent;