import React, { useState, useEffect } from 'react';
import './Register.css';

function Register() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        username: '',
        password: '',
        confirmPassword: '',
        plabPathway: '',
        role:1
    });

    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [plabPathways, setPlabPathways] = useState([]);

    useEffect(() => {
        const fetchPlabPathways = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_BASE_URL+'/getPlabPathwayList');
                const data = await response.json();
                if (data && data.length) {
                    setPlabPathways(data);
                }
            } catch (error) {
                console.error('Error fetching plab pathways:', error);
            }
        };

        fetchPlabPathways();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        // Regular expression to match only alphabetic characters
        const regExp = /^[A-Za-z]*$/;
    
        // Apply validation only to specific fields
        if (name === "firstName" || name === "lastName") {
            // If the value matches the regular expression, or is an empty string
            if (regExp.test(value) || value === '') {
                setFormData({
                    ...formData,
                    [name]: value
                });
            }
        } else {
            // For other fields, no validation is applied
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };
    

    const isValidEmail = email => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return re.test(String(email).toLowerCase());
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Clear previous messages
        setError('');
        setSuccessMessage('');

        // Validation
        if (!formData.firstName || !formData.lastName || !formData.username || !formData.password || !formData.confirmPassword || !formData.plabPathway) {
            setError('Please fill in all fields.');
            return;
        }

        if (!isValidEmail(formData.username)) {
            setError("Invalid email format");
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
        
            const data = await response.json();
        
            if (!response.ok) {
                // Use the message from the backend if the response is not OK
                throw new Error(data.message || 'An error occurred during registration');
            }
        
            if (data.status === 1) {
                setSuccessMessage('Registration successful! Please check your email to verify your account.');
                setTimeout(() => {
                    window.location.href = "/login";
                }, 2000);
            } else {
                setError(data.message || 'Registration error. Please try again.');
            }
        } catch (error) {
            console.error('Error registering:', error);
            setError(error.message || 'Unexpected error. Please try again.');
        }
        
    }

    return (
        <div className="register-container">
            <h1 className="register-title">Create an Account</h1>
            <form onSubmit={handleSubmit} className="register-form">
                {error && <div className="error-message">{error}</div>}
                {successMessage && <div className="success-message">{successMessage}</div>}
                <div className="input-group">
                    <input 
                        type="text" 
                        name="firstName" 
                        placeholder="First Name" 
                       
                        value={formData.firstName} 
                        onChange={handleChange} 
                        required 
                    />
                    <input 
                        type="text" 
                        name="lastName" 
                        placeholder="Last Name" 
                       
                        value={formData.lastName} 
                        onChange={handleChange} 
                        required 
                    />
                </div>
                <input 
                    className="input-full-width"
                    type="email"   
                    name="username"  
                    placeholder="Email" 
                    value={formData.email}
                    onChange={handleChange} 
                    required 
                />
                <select 
                    className="input-full-width"
                    name="plabPathway"
                    value={formData.plabPathway}
                    onChange={handleChange}
                    required
                >
                    <option value="">Select Pathway</option>
                    {plabPathways.map(pathway => (
                        <option key={pathway._id} value={pathway._id}>{pathway.plabFormat+" - "+pathway.country}</option>
                    ))}
                </select>
                <input 
                    className="input-full-width"
                    type="password" 
                    name="password" 
                    placeholder="Password" 
                    value={formData.password} 
                    onChange={handleChange} 
                    required 
                />
                <input 
                    className="input-full-width"
                    type="password" 
                    name="confirmPassword" 
                    placeholder="Confirm Password" 
                    value={formData.confirmPassword} 
                    onChange={handleChange} 
                    required 
                />
                <button className="register-btn" type="submit">Register</button>
            </form>
        </div>
    );
}

export default Register;
