import React, { useState, useEffect } from 'react';
import 'antd/dist/reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Layout, Button, FloatButton, Space, Card, Steps, theme, notification } from 'antd';
import { Checkbox, Select, Input, InputNumber, Switch, DatePicker, Form, Flex } from 'antd';
import { PlusCircleOutlined, FormOutlined, PlusOutlined, CloseOutlined, MinusCircleOutlined,StepBackwardOutlined,StepForwardOutlined, QuestionOutlined } from '@ant-design/icons';
import { QuestionTypes } from './QuestionTypes';
import { fetchWrapper } from './fetchWrapper.js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


import { useUser } from './UserContext';

import HeaderComponent from './HeaderComponent';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { useLocation, useParams } from 'react-router-dom';

import './ConsumerFillResume.css';

async function fetchAndSetResumeFormat(resumeId,upgrade,userId,plabId,logout) {
    try {

        const response = await fetchWrapper('/user/add-edit-resume', logout, {
            method: 'POST', // Change to GET if you're retrieving data
            headers: {
                'Content-Type': 'application/json',
            },

            body: JSON.stringify({ resumeId,upgrade,userId,plabId })
            // You can add more headers as needed, such as authorization headers
            // credentials: 'include' // This is necessary to send cookies along with the request
        });

        if (response.ok) {
            const tempResponse = await response.json();

            return tempResponse;
        } else {
            // Handle HTTP errors, if any
            const errorMessage = await response.text();
            throw new Error(errorMessage);
        }
    } catch (error) {
        // Handle network errors and exceptions
        console.error('Error fetching resume details:', error);
        notification.error({ message: 'Error fetching resume details.' });
    }
}



const QuestionComponent = ({ questionInfo, groupRepeatFor, answerInfo, updateQuestionAnswer, addQuestionToSection, removeGroupToSection, addGroupToSection, removeQuestionToSection }) => {
    const { _id, type, options } = questionInfo;
    const handleAnswerChange = function (answer) {
        let newAnswer = { ...answerInfo, 'answer': answer };
        updateQuestionAnswer(newAnswer);
    }
    let thisValue = answerInfo?.answer?.value || '';
    const renderAnswerSection = () => {
        if (type == 'text' || type == 'date') {
            return (
                <input value={thisValue} onChange={(e) => {

                    handleAnswerChange({ value: e.target.value })
                }} type={type} className='custom-form-control' />
            )
        }
        if (type == 'datetime') {
            return (
                <input value={thisValue} onChange={(e) => {

                    handleAnswerChange({ value: e.target.value })
                }} type="datetime-local" className='custom-form-control' />
            )
        }
        if (type == 'number') {
            return (
                <input type={type} value={thisValue} onChange={(e) => {

                    handleAnswerChange({ value: e.target.value })
                }} className='custom-form-control' />
            )
        }
        if (type == 'email') {
            return (
                <input type={type} value={thisValue} onChange={(e) => {

                    handleAnswerChange({ value: e.target.value })
                }} className='custom-form-control' />
            )
        }
        if (type == 'richtext') {
            return (
                <ReactQuill key={questionInfo._id} value={thisValue} onChange={(editor) => {

                    handleAnswerChange({ value: editor })
                }} theme="snow" />
            )
        }
        if (type == 'select') {
            return (
                <select value={thisValue} type='text' onChange={(e) => {

                    handleAnswerChange({ value: e.target.value })
                }} className='custom-form-control'>
                    <option key='empty' value=''>Choose</option>
                    {
                        questionInfo.options.map((oi, i) => {
                            return <option key={i} value={oi.value}>{oi.value}</option>
                        })
                    }
                </select>
            )
        }
        if (type == 'select-multiple') {
            return (
                <select value={thisValue} multiple onChange={(e) => {

                    handleAnswerChange({ value: [e.target.value] })
                }} className='custom-form-control'>
                    <option key='empty' value=''>Choose</option>
                    {
                        questionInfo.options.map((oi, i) => {
                            return <option key={i} value={oi.value}>{oi.value}</option>
                        })
                    }
                </select>
            )
        }
        if (type == 'checkbox') {
            return (
                <div className='options-group'>
                    {
                        options.map((thisOption, i) => {
                            return (
                                <div key={i} className='option-cover'>
                                    <label>
                                        <input
                                            checked={thisValue.includes(thisOption.value) ? true : false}
                                            value={thisOption.value}
                                            onChange={(e) => {
                                                let answer = Array.isArray(answerInfo?.answer?.value) ? answerInfo?.answer?.value : [];
                                                if (e.target.checked) {
                                                    answer.push(e.target.value);
                                                } else {
                                                    answer = answer.filter((t) => {
                                                        return t != e.target.value
                                                    })
                                                }
                                                handleAnswerChange({ value: [...new Set(answer)] })
                                            }} type='checkbox' /> {thisOption.value}
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
        if (type == 'radio') {
            return (
                <div className='options-group'>
                    {
                        options.map((thisOption, i) => {
                            return (
                                <div key={i} className='option-cover'>
                                    <label>
                                        <input 
                                            checked={thisOption.value == thisValue ? true : false}
                                        value={thisOption.value} onChange={(e) => {
                                            handleAnswerChange({ value: e.target.value })
                                        }} type='radio' name={`answer_${_id}`} /> {thisOption.value}
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
    }
    return (
        <div className='questionCover' data-type={questionInfo.type}>
            <div className='questionLabel'>{questionInfo.label}</div>
            {renderAnswerSection()}
            {
                Boolean(questionInfo.isRepeative) && (
                    <div style={{ marginTop: '20px', 'textAlign': 'right' }}>
                        <Button onClick={() => {
                            addQuestionToSection(questionInfo)
                        }
                        } type="default" style={{ minWidth: '100px' }} >
                            Add
                        </Button>
                        {
                            Boolean(questionInfo.repeatOf) && (
                                <Button onClick={() => {
                                    removeQuestionToSection(questionInfo)
                                }
                                } type="default" style={{ minWidth: '100px', marginLeft: '20px' }} >
                                    Delete
                                </Button>
                            )
                        }
                    </div>
                )
            }
            {
                Boolean (questionInfo.groupId && groupRepeatFor[questionInfo.groupId]?.lastQuestionId == questionInfo._id) && (
                    <div style={{ marginTop: '20px', 'textAlign': 'right' }}>
                        <Button onClick={() => {
                            addGroupToSection({ questionId: questionInfo._id, originalGroupId: groupRepeatFor[questionInfo.groupId].originalGroupId })
                        }
                        } type="default" style={{ minWidth: '100px' }} >
                            Add
                        </Button>
                        {
                            Boolean(groupRepeatFor[questionInfo.groupId].repeatOf) && (
                                <Button onClick={() => {
                                    removeGroupToSection({ groupId: questionInfo.groupId })
                                }
                                } type="default" style={{ minWidth: '100px', marginLeft: '20px' }} >
                                    Delete
                                </Button>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}

const ResumeSection = ({ section, sectionAnswer, updateSectionAnswer, resumeFormat, setResumeFormat }) => {
    if (!(section && section._id)) {
        return (
            <div></div>
        )
    }
    let thisSectionAnswer = sectionAnswer || { questions: [] };
    let groupRepeatFor = {};
    section?.groups?.forEach(function (thisGroup) {
        if (thisGroup.isRepeative && thisGroup?.questionIdsList?.length) {
            groupRepeatFor[thisGroup._id] = {
                ...thisGroup,
                originalGroupId: thisGroup.repeatOf ?? thisGroup._id,
                lastQuestionId: thisGroup?.questionIdsList[thisGroup?.questionIdsList?.length - 1]
            }
        }
    })
    const updateQuestionAnswer = (answerInfo) => {
        if (!(answerInfo && answerInfo.questionId)) {
            console.error('Invalid answerInfo:', answerInfo);
            return;
        }

        const existingQuestion = thisSectionAnswer.questions.find(obj => obj.questionId === answerInfo.questionId);

        if (existingQuestion) {
            // Update existing question
            existingQuestion.answer = answerInfo.answer;
        } else {
            // Add new question
            thisSectionAnswer.questions.push(answerInfo);
        }

        updateSectionAnswer({ sectionId: section._id, answerInfo: { questions: [...thisSectionAnswer.questions] } });
    };
    const getDuplicateSuffix = ({ questionId, originalGroupId }) => {
        const existingSection = resumeFormat.sections.find((t) => { return t._id === section._id });

        if (!existingSection) {
            return;
        }
        if (originalGroupId) {
            let count = existingSection?.groups?.filter((t) => {
                return t.repeatOf == originalGroupId;
            })?.length;
            return count + 1;
        }
        let count = existingSection?.questions?.filter((t) => {
            return t.repeatOf == questionId;
        })?.length;
        return count + 1;
    }
    const addGroupToSection = ({ originalGroupId, questionId }) => {
        const existingSection = resumeFormat.sections.find((t) => { return t._id === section._id });
        if (!existingSection) {
            return;
        }
        let targetGroup = existingSection.groups.find((tmp) => {
            return tmp._id == originalGroupId
        })
        let questionIdsList= [];
        let newSuffix = getDuplicateSuffix({ originalGroupId });
        let newGroup = {
            ...targetGroup,
            repeatOf: originalGroupId,
            questionIdsList:questionIdsList,
            _id: `${targetGroup._id}_${newSuffix}`,
        }
        let newQuestionsList = [];
        let lastIndex = -1;
        let originalGroupQuestions = existingSection.questions.filter((t, ti) => {
            if (t._id == questionId) {
                lastIndex = ti;
            }
            if (t.groupId == originalGroupId) {
                return true;
            }
            
        })
        originalGroupQuestions.forEach((tmpQuestion) => {
            let obj = {
                ...tmpQuestion,
                '_id': `${tmpQuestion._id}_${newSuffix}`,
                groupId:newGroup._id,
            }
            questionIdsList.push(obj._id);
            newQuestionsList.push(obj);
        })
        newGroup.questionIdsList = questionIdsList;
        existingSection.questions.splice(lastIndex + 1, 0, ...newQuestionsList);
        let updatedSections = resumeFormat.sections.map((thisSection)=> {
            return thisSection._id === section._id ? { ...existingSection, groups: [...thisSection.groups, newGroup], questions: [...existingSection.questions] } : thisSection
        });
        let updateObj = { sections: updatedSections }
        setResumeFormat({ ...resumeFormat, ...updateObj });
    }
    const removeGroupToSection = ({groupId}) => {
        const existingSection = resumeFormat.sections.find((t) => { return t._id === section._id });

        if (!existingSection) {
            return;
        }
        let updatedQuestions = existingSection.questions.filter((t) => {
            return t.groupId != groupId;
        })
        let updatedGroups = existingSection.groups.filter((t) => {
            return t._id != groupId
        })

        let updatedSections = resumeFormat.sections.map((thisSection)=> {
            return thisSection._id === section._id ? { ...existingSection, groups: [...updatedGroups], questions: [...updatedQuestions] } : thisSection
        });

        let updateObj = { sections: updatedSections }
        setResumeFormat({ ...resumeFormat, ...updateObj });
    }
    const addQuestionToSection = ({ _id, repeatOf }) => {
        let questionId = repeatOf ?? _id;
        const existingSection = resumeFormat.sections.find((t) => { return t._id === section._id });

        if (!existingSection) {
            return;
        }
        let targetQuestion = existingSection.questions.find((thisQuestion)=> {
            return thisQuestion._id == questionId
        })
        let newQuestion = {
            ...targetQuestion,
            repeatOf: questionId,
            '_id': `${questionId}_${getDuplicateSuffix({ questionId: questionId })}`
        };

        let updatedSections = resumeFormat.sections.map((thisSection)=> {
            return thisSection._id === section._id ? { ...existingSection, questions: [...thisSection.questions, newQuestion] } : thisSection
        });

        let updateObj = { sections: updatedSections }
        setResumeFormat({ ...resumeFormat, ...updateObj });
    }
    const removeQuestionToSection = ({_id}) => {
        let questionId = _id;
        const existingSection = resumeFormat.sections.find((t) => { return t._id === section._id });

        if (!existingSection) {
            return;
        }
        let updatedQuestions = existingSection.questions.filter((t) => {
            return t._id != _id;
        })

        let updatedSections = resumeFormat.sections.map((thisSection)=> {
            return thisSection._id === section._id ? { ...existingSection, questions: [...updatedQuestions] } : thisSection
        });

        let updateObj = { sections: updatedSections }
        setResumeFormat({ ...resumeFormat, ...updateObj });
    }
    let thisSectionQuestions = [];
    section.questions.forEach((tmp) => {
        if (!tmp.type) {
            return;
        }
        thisSectionQuestions.push(tmp);
        let repeatedItems = thisSectionAnswer.questions.filter((tmp1) => {
            return tmp1.type && tmp1.repeatOf == tmp.questionId;
        })
        thisSectionQuestions.push(...repeatedItems)
    })
    return (
        <div className='sectionCover'>
            <div className='sectionHeader'>
                <h3>{section.title}</h3>
                <div className='sectionDescription'>{section.description}</div>
            </div>
            <div className='sectionQuestionsList'>
                {
                    thisSectionQuestions.map((questionInfo, i) => {
                        const answerInfo = (thisSectionAnswer.questions.find((t) => {
                            return t.questionId == questionInfo._id;
                        }) || { questionId: questionInfo._id, answer: '' });
                        return (
                            <QuestionComponent groupRepeatFor={groupRepeatFor} removeGroupToSection={removeGroupToSection} addGroupToSection={addGroupToSection} addQuestionToSection={addQuestionToSection} removeQuestionToSection={removeQuestionToSection} key={i} updateQuestionAnswer={updateQuestionAnswer} answerInfo={answerInfo} questionInfo={questionInfo} />
                        )
                    })
                }
            </div>
        </div>
    )
}

const ConsumerFillResumeComponent = () => {
    const [resumeFormat, setResumeFormat] = useState(null);
    // const [resume, setResume] = useState(null);
    const [selectedSectionId, setSectionId] = useState('');
    const [resumeAnswer, setResumeAnswer] = useState([]);
    const [originalResumeFormat, setOriginalResumeFormat] = useState();
    const { logout } = useUser();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const resumeId = params.get("resumeId");
    const upgrade = params.get("upgrade") || "false";
    let userId= params.get("userId") || null;
    let plabId= params.get("plabId") || null;
    


    const saveResume = async () => {
       


        try {
            let newResumeFormat = processExtraQuestions({ resumeAnswer, originalResumeFormat, resumeFormat });
            const response = await fetchWrapper('/user/save-user-resume', logout, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newResumeFormat: newResumeFormat, resumeFormatId: resumeFormat._id, resumeAnswer, upgrade, resumeId, userId, plabId }), // Include resumeId if it exists
            });

            if (response.ok) {
                const responseData = await response.json();
                message.success('Form data saved successfully!');

                // Set a delay before redirecting
                setTimeout(() => {
                  window.location.href = '/consumer';
                }, 3000); 
            } else {
                const errorResponse = await response.json(); // Parse the error response
                console.error('Failed to save form data:', errorResponse.message);
                message.error(errorResponse.message); // Display the error message from the server
            }
        } catch (error) {
            // Handle any network or other errors here
            console.error('An error occurred:', error);
            message.error('An error occurred. Please try again later.');
        }
    };

    useEffect(() => {
        document.body.classList.add("consumerResumePage");


       
        async function fetchResumeData() {
            try {
                const data = await fetchAndSetResumeFormat(resumeId,upgrade,userId,plabId,logout);
                setOriginalResumeFormat(JSON.parse(JSON.stringify(data.resumeFormat)));
                setResumeFormat(data.resumeFormat);
                setSectionId(data.resumeFormat.sections[0]._id)
                try{
                    setResumeAnswer(data.resume.resumeAnswer)
                }
                catch(e){

                }
              
                // setResume(data.resume);

            } catch (error) {
                console.error('Error fetching resume format:', error);
                notification.error({ message: 'Error fetching resume format.' });
            }
        }

        fetchResumeData();
    }, [logout]);

    if (!(resumeFormat && resumeFormat.sections && resumeFormat.sections.length)) {
        return <div></div>;
    }
    const moveToSection = (nextOrPrev) => {
        let filteredArr = resumeFormat.sections.filter((t)=> {
            if (!(t?.availableOn?.[0]?.sectionId && t?.availableOn?.[0]?.questionId)) {
                return true;
            }
            let { answers, when, sectionId,questionId } = t.availableOn[0];
            //check conditions
            let sectionAnswer = resumeAnswer.find((tmp)=> {
                return tmp.sectionId == sectionId;
            })
            if(!sectionAnswer) {
                //no answer
                return true;
            }
            let thisAnswerValue = sectionAnswer?.questions.find((tmp) => {
                return tmp.questionId == questionId;
            })?.answer?.value;
            if(when=='contains') {
                return answers.includes(thisAnswerValue);
            } else if(when =='notContains') {
                return !answers.includes(thisAnswerValue);
            }
            return false;
        })
        let tmpInd = filteredArr.findIndex((t)=> {
            return t._id == selectedSectionId;
        })
        return nextOrPrev ? filteredArr[tmpInd + 1] : filteredArr[tmpInd - 1];
    }
    const next = () => {
        setSectionId(getNextSection()._id);
    };
    const getNextSection = () => {
        return moveToSection(true)
    }
    const getPrevSection = () => {
        return moveToSection(false)
    }

    const prev = () => {
        setSectionId(getPrevSection()._id);
    };
    const updateSectionAnswer = ({ sectionId, answerInfo }) => {
        if (!sectionId || typeof answerInfo !== 'object') {
            console.error('Invalid input:', { sectionId, answerInfo });
            return;
        }
        const existingSection = resumeAnswer.find((t) => t.sectionId === sectionId);

        if (existingSection) {
            // Update existing section
            existingSection.questions = answerInfo.questions;
        } else {
            // Add new section
            resumeAnswer.push({ sectionId, questions: answerInfo.questions });
        }

        setResumeAnswer([...resumeAnswer]);
    };
    let selectedSectionInfo = resumeFormat.sections.find((t)=> {
        return selectedSectionId == t._id
    })
    return (
        <div className='resumeBuilderCover'>
            <div className='resumeBuilderHeader' style={{ display: 'none' }}>
                <div style={{ display: 'flex' }}>
                    <h3 style={{ margin: 'auto' }}>Build Your Resume</h3>
                </div>
                <div className='sectionsListCover'>
                    <div className='sectionTabsCover'>
                        {
                            resumeFormat.sections.map((thisSection, i) => {
                                return (
                                    <div key={i} onClick={(e) => {
                                        setSectionId(i);
                                    }} className={`sectionTabItem ${i === selectedSectionId ? 'active' : ''}`}>
                                        <span>{thisSection.title}</span>
                                        <div></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className='sectionsContentCover'>
                {
                    <ResumeSection resumeFormat={resumeFormat} setResumeFormat={setResumeFormat} updateSectionAnswer={updateSectionAnswer} sectionAnswer={resumeAnswer.find((t) => { return t.sectionId == selectedSectionInfo?._id })} section={selectedSectionInfo} />
                }
            </div>
            <div className='sectionCTACover'>
                <Flex justify='space-between' align='center'>
                    <div>
                        {Boolean(getPrevSection()) && (
                            <Button style={{ margin: '0 8px' }} onClick={() => prev()} icon={<StepBackwardOutlined />}>
                                 {getPrevSection().title}
                            </Button>
                        )}
                    </div>

                    <div>
                        <Button type="primary" style={{ minWidth: '200px' }} onClick={saveResume}>
                            Save
                        </Button>
                    </div>
                    <div>
                        {Boolean(getNextSection()) && (
                            <Button type="default" onClick={() => next()} icon={<StepForwardOutlined />}>
                                {
                                    getNextSection().title
                                }
                            </Button>
                        )}
                    </div>
                </Flex>

            </div>
        </div>
    )
};
let counter = 1;
function getUniqueId() {
    return counter++
}
function processExtraQuestions({ resumeAnswer, originalResumeFormat, resumeFormat }) {
    let newSections = originalResumeFormat.sections.map((thisSection) => {
        let modifiedSection = resumeFormat.sections.find((t) => { return t._id == thisSection._id });
        if (!(modifiedSection && modifiedSection.questions?.length)) {
            return thisSection;
        }
        let newQuestion = [];
        let newGroups = [];
        let groupRepeatFor = {};
        let availGroupIds = [];
        thisSection.groups.forEach(thisGroup => {
            if(thisGroup.isRepeative && !modifiedSection.groups.find((t)=> { return t._id==thisGroup._id})) {
                //group is deleted
                return;
            }
            newGroups.push(thisGroup)
            if (thisGroup.isRepeative && thisGroup?.questionIdsList?.length) {
                groupRepeatFor[thisGroup._id] = {
                    addedGroups: [],
                    ...thisGroup,
                    originalGroupId: thisGroup.repeatOf ?? thisGroup._id,
                    lastQuestionId: thisGroup?.questionIdsList[thisGroup?.questionIdsList?.length - 1]
                }
                modifiedSection.groups.forEach((t) => {
                    if (t.repeatOf != thisGroup._id) {
                        return;
                    }
                    if(groupRepeatFor[thisGroup._id].addedGroups.includes(t.id)) {
                        return;
                    }
                    let duplicateGroup = {
                        ...thisGroup,
                        questionIdsList: t.questionIdsList,
                        repeatOf: thisGroup._id,
                        _id: t._id,
                    }
                    groupRepeatFor[thisGroup._id].addedGroups.push(t._id)
                    newGroups.push(duplicateGroup);
                })
            }
        })
        availGroupIds = newGroups.map((t) => {
            return t._id;
        })
        thisSection.questions.forEach(thisQuestion => {
            if (thisQuestion.groupId && !availGroupIds.includes(thisQuestion.groupId)) {
                return; //group is not available , so dont add those questions
            }
            if(thisQuestion.repeatOf) {
                return; // will handle in duplicate question
            }
            newQuestion.push(thisQuestion);
            if (thisQuestion.groupId) {
                if (groupRepeatFor[thisQuestion.groupId]?.lastQuestionId == thisQuestion._id) {
                    let newGroupQuestions = modifiedSection.questions.filter((t1) => {
                        return groupRepeatFor[thisQuestion.groupId]?.addedGroups?.includes(t1.groupId);
                    })
                    newQuestion = [...newQuestion, ...newGroupQuestions]
                }
                return;
            }
            if (!thisQuestion.isRepeative) {
                return;
            }
            //add duplicate questions
            modifiedSection.questions.forEach(function (tmpQuestion) {
                if (tmpQuestion.repeatOf != thisQuestion._id) {
                    return;
                }
                let duplicateQuestion = {
                    ...thisQuestion,
                    _id: tmpQuestion._id
                }
                newQuestion.push(duplicateQuestion);
            })
        })
        thisSection.questions = removeDuplicates(newQuestion);
        thisSection.groups = removeDuplicates(newGroups);
        return thisSection;
    })
    return { ...originalResumeFormat, sections: newSections };
}
function removeDuplicates(arr) {
    const uniqueIds = new Set();
    const result = [];
  
    for (const obj of arr) {
      if (!uniqueIds.has(obj._id)) {
        uniqueIds.add(obj._id);
        result.push(obj);
      }
    }
  
    return result;
  }
export default ConsumerFillResumeComponent;