import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Layout, Card, Typography, Select, Tag, Spin, notification } from 'antd';
import { useUser } from './UserContext';
import HeaderComponent from './HeaderComponent';
import { fetchWrapper } from './fetchWrapper.js';
import './AdminDashboard.css';

const { Title } = Typography;
const { Option } = Select;


const AdminDashboard = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const { logout } = useUser();
    const [users, setUsers] = useState([]);
    const [plabs, setPlabs] = useState([]);
    const [resumes, setResumes] = useState([]);
    
    const [searchQuery, setSearchQuery] = useState('');
    const [approvalMessage, setApprovalMessage] = useState('');
    const [loadingRows, setLoadingRows] = useState([]);
    const [approvingUsers, setApprovingUsers] = useState([]);

    useEffect(() => {
        const fetchUsersAndPlabs = async () => {
            try {
                const [usersResponse, plabsResponse,resumeResponse] = await Promise.all([
                    fetchWrapper('/setup/get-users',logout,{}),
                    fetchWrapper('/setup/get-plabList',logout,{}),
                    fetchWrapper('/setup/getResumes',logout,{})
                ]);

                const usersData = await usersResponse.json();
                const plabsData = await plabsResponse.json();
                const resumeData =  await resumeResponse.json();

                if (usersData.status === 1) {
                    setUsers(usersData.users);
                }
                if (plabsData.status === 1) {
                    setPlabs(plabsData.plabList);
                }

                if (resumeData.status === 1) {
                    setResumes(resumeData.resumes);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchUsersAndPlabs();
    }, []);

    const handlePlabChange = async (userId, plabId) => {
        setLoadingRows([...loadingRows, userId]);
        try {
            const response = await fetchWrapper(`/setup/update-plab-for-user`,logout, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "userId": userId, "plab": plabId })
            });

            const data = await response.json();
            if (data.status === 1) {
                setUsers(prevUsers =>
                    prevUsers.map(user =>
                        user._id === userId ? { ...user, plab: plabId } : user
                    )
                );
            } else {
                console.error('Error updating plab for user:', data.message);
            }

            setLoadingRows(prevLoadingRows => prevLoadingRows.filter(id => id !== userId));
        } catch (error) {
            console.error('Error updating plab for user:', error);
        }
    };

    const handleUserApproval = async (userId) => {
        setApprovingUsers(prev => [...prev, userId]);

        try {
            const response = await fetchWrapper(`/setup/approve-user`,logout, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "userId": userId })
            });

            const data = await response.json();
            if (data.status === 1) {
                setUsers(prevUsers =>
                    prevUsers.map(user =>
                        user._id === userId ? { ...user, isActive: true } : user
                    )
                );
            } else {
                console.error('Error approving user:', data.message);
            }
        } catch (error) {
            console.error('Error approving user:', error);
        } finally {
            setApprovingUsers(prev => prev.filter(id => id !== userId));
        }
    };

    const handleEditResume = (userId,plabId) => {

        let userResumes = resumes.filter(resume => {
            return resume.createdBy === userId;
        });

        if(userResumes.length > 0){
            window.location.href = `/addEditResumeAdmin?resumeId=`+userResumes[0]["_id"]
        }
        else{
            window.location.href = `/addEditResumeAdmin?userId=`+userId+"&plabId="+plabId;
        }

        
        // Delete resume logic...
      };
      const handleViewResume = (userId) => {
        let userResumes = resumes.filter(resume => {
            return resume.createdBy === userId;
        });

        if(userResumes.length > 0){
            window.location.href = `/view-resume-pdfAdmin?resumeId=`+userResumes[0]["_id"];
        }
        else{
            notification.error({ message: 'No resume found.' });        }
       
        // Delete resume logic...
      };

    //   const handleDownloadResume = (userId) => {
    //     let userResumes = resumes.filter(resume => {
    //         return resume.createdBy === userId;
    //     });

    //     if(userResumes.length > 0){
    //         window.location.href = `/view-resume-pdfAdmin?resumeId=`+userResumes[0]["_id"]+"&triggerPrint="+true;
    //     }
    //     else{
    //         notification.error({ message: 'No resume found.' });        }
       
    //     // Delete resume logic...
    //   };


    const columns = [
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Email',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Email Verified',
            dataIndex: 'isEmailVerified',
            key: 'isEmailVerified',
            render: isEmailVerified => (
                isEmailVerified ? <Tag color="green">Verified</Tag> : <Tag color="volcano">Not Verified</Tag>
            ),
        },
        
        {
            title: 'Plab',
            dataIndex: 'plab',
            key: 'plab',
            render: (plab, user) => (
                loadingRows.includes(user._id) ? 
                <Spin size="small" /> :
                <Select 
                    style={user.isEmailVerified ? {} : {opacity: 0.5}}
                    value={plabs.find(plabDoc => plabDoc._id === plab) ? `${plabs.find(plabDoc => plabDoc._id === plab).plabFormat} - ${plabs.find(plabDoc => plabDoc._id === plab).country}` : "unassigned"}
                    onChange={(selectedPlabId) => handlePlabChange(user._id, selectedPlabId)}>
                    {plabs.map(plab => (
                        <Option key={plab._id} value={plab._id}>{plab.plabFormat + " - " + plab.country}</Option>
                    ))}
                </Select>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, user) => {
                if (user.isEmailVerified && !user.isActive) {
                    return (
                        <Button 
                            type="primary" 
                            onClick={() => handleUserApproval(user._id)}
                            loading={approvingUsers.includes(user._id)}
                        >
                            Approve
                        </Button>
                    );
                } else if (user.isActive) {
                    return (
                        <div className="button-container">
                            <Button
                                type="primary"
                                onClick={() => handleEditResume(user._id,user.plab)}
                                style={{ marginRight: 8 }} // Inline style for spacing
                            >
                                Edit Resume
                            </Button>
                            {}
                            <Button
                                onClick={() => handleViewResume(user._id)}
                            >
                                View
                            </Button>
                            {/* <Button
                                onClick={() => handleDownloadResume(user._id)}
                            >
                                Download
                            </Button> */}
                          
                        </div>
                    );

                }
                return null;
            }
        }
    ];
    return (
        <Layout style={{ minHeight: '100vh', backgroundColor: '#f4f4f4', fontFamily: 'Arial, sans-serif' }}>
            <div style={{ zIndex: 1000, backgroundColor: '#fff' }}> {/* This div will ensure the header is always on top */}
                <HeaderComponent logout={logout} />
            </div>
            <div style={{ padding: '20px', marginTop: '80px' }}>
                <Card style={{ 
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)', 
                    marginBottom: '20px', 
                    borderRadius: '8px'
                }}>
                    <Title level={3} style={{ marginBottom: '20px' }}>User Management</Title>
                    <Input 
                        placeholder="Search by name or email" 
                        value={searchQuery} 
                        onChange={(e) => setSearchQuery(e.target.value.toLowerCase())} 
                        style={{ 
                            marginBottom: '20px',
                            padding: '10px 15px',
                            borderRadius: '4px',
                            borderColor: '#ddd'
                        }} 
                        allowClear
                    />
                   <Table 
                        dataSource={users.filter(user => 
                            user.firstName.toLowerCase().includes(searchQuery) || 
                            user.username.toLowerCase().includes(searchQuery)
                        )} 
                        columns={columns}
                        pagination={{
                            current: currentPage,
                            pageSize: pageSize,
                            onChange: (page) => setCurrentPage(page),
                            onShowSizeChange: (_, size) => {
                                setCurrentPage(1);
                                setPageSize(size);
                            },
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '20', '30', '50'],
                        }}
                        style={{ borderColor: '#eee' }}
                    />
                </Card>
            </div>
        </Layout>
    );
};

export default AdminDashboard;
