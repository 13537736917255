import { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    const [userRole, setUserRole] = useState(localStorage.getItem('userRole'));

    const logout = async () => {
        try {
            // Call backend server to handle logout
            const response = await fetch('/logout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Assuming the token is stored in local storage under the key 'jwtToken'
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.ok) {
                // If logout was successful on the backend

                // Remove the stored user role and JWT token from local storage
                localStorage.removeItem('userRole');
                localStorage.removeItem('token');

                // Update the state to reflect the user has been logged out
                setUserRole(null);

                // Redirect to login page after logout
                window.location.href = '/login';
            } else {
                console.error('Failed to logout on the server side');
            }
        } catch (error) {
            console.error('Error while logging out:', error);
        }
    };

    return (
        <UserContext.Provider value={{ userRole, setUserRole, logout }}>
            {children}
        </UserContext.Provider>
    );
};
