// fetchWrapper.js
const apiUrl = process.env.REACT_APP_BASE_URL;
export const fetchWrapper = async (url, logout,options) => {
    const token = localStorage.getItem('token');

    // Default headers - omit Content-Type to let the browser set it for FormData
    const defaultHeaders = token ? { 'Authorization': `Bearer ${token}` } : {};

    const isFormData = options.body instanceof FormData;

    // If the body is not FormData, set Content-Type to 'application/json'
    if (!isFormData) {
        defaultHeaders['Content-Type'] = 'application/json';
    }

    const mergedOptions = {
        ...options,
        headers: {
            ...defaultHeaders,
            ...options.headers,
        },
    };



    const response = await fetch(`${apiUrl}${url}`, mergedOptions);
  
    // Check for unauthorized status
    if (response.status === 401 ||  response.status === 403) {
        if (logout) {
            logout();
        }
      
      // Handle 401 status response.
      // For example, redirecting to a login page or showing an alert.
      // You can adapt the logic based on your application's needs.
    //   alert("Unauthorized! Please login again.");
    //   // redirect to login or handle logout
      
    //   // You might want to throw an error here so it can be caught by the calling function
    //   throw new Error("Unauthorized");
        
    }
  
    return response;
  };
  