import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Modal, Alert, Typography, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import './Login.css'; 

const { Title, Text } = Typography;

function Login() {
  const [username, setUsername] = useState(localStorage.getItem('rememberedUsername') || "");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(!!localStorage.getItem('rememberedUsername'));
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(null);

  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleUsernameChange = e => {
    setUsername(e.target.value);
  };
  const handleRegister = () => {
    navigate('/register');
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value);
  };

  const handleForgotPassword = (values) => {
    setForgotPasswordLoading(true);

    fetch(process.env.REACT_APP_BASE_URL+'/forgot-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: values.email })
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 1) {
        setForgotPasswordSuccess(data.message);
      } else {
        setError(data.error || 'Unexpected error occurred.');
      }
    })
    .catch(() => {
      setError('An error occurred while processing your request.');
    })
    .finally(() => {
      setForgotPasswordLoading(false);
      setShowForgotPasswordModal(true);
    });
  };

  const handleLogin = () => {
    const loginApiEndpoint = process.env.REACT_APP_BASE_URL + '/login';
  
    fetch(loginApiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username,
        password
      })
    })
    .then(response => {
      if (response.status === 400) {
        throw new Error('Username and password are required');
      } else if (response.status === 401) {
        throw new Error('Invalid credentials');
      } else if (response.status === 403) {
        return response.json(); // Process further in the next .then() block
      } else if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      if (data.error) {
        switch (data.error) {
          case "not_verified":
            setError("Please verify your email before logging in.");
            break;
          case "inactive":
            setError("Your account is not active. Contact support.");
            break;
          default:
            setError("An unknown error occurred. Please try again.");
            break;
        }
        return; // Return early, so the rest of the function does not execute
      }
  
      // Login successful, proceed with role-based redirection and local storage update
      const userRole = Number(data.role);  // Convert role to a number
      localStorage.setItem('userRole', userRole.toString());
      localStorage.setItem('token', data.token.toString());
  
      // Uncomment and use if you have a 'remember me' functionality
      // if (rememberMe) {
      //   localStorage.setItem('rememberedUsername', username);
      // } else {
      //   localStorage.removeItem('rememberedUsername');
      // }
  
      if (userRole === 2) {
        window.location.href = '/admin';
      } else if (userRole === 1) {
        window.location.href = '/consumer';
      } else {
        setError("Unknown user role");
      }
    })
    .catch(error => {
      setError('Error: ' + error.message);
    });
  };
  




  const closeModal = () => {
    setShowForgotPasswordModal(false);
    setError(null); 
    setForgotPasswordSuccess(null); 
  };

  
  return (
    <Row align="middle" className="loginWrapper mx-auto shadow-sm rounded">
      <Col span={12}>
        <div className="leftWrapper rounded-start">
          {/* Insert your image here */}
        </div>
      </Col>
      <Col span={12}>
        <div className="loginFormWrapper" >
          <Title level={3} className="text-center">Welcome Back!</Title>
          <Text type="secondary" className="text-center">
            We're happy to see you return! Please login to continue.
          </Text>
          {error && <Alert message="Error" description={error} type="error" showIcon />}
          
          <Form 
            layout="vertical" 
            onFinish={handleLogin} 
            className="p-3 mx-5" 
            style={{ margin: '10px', padding: '10px' }}
          >
            <Form.Item label="Email address">
              <Input type="email" onChange={handleUsernameChange} />
            </Form.Item>
            <Form.Item label="Password">
              <Input.Password onChange={handlePasswordChange} />
            </Form.Item>
            <Form.Item>
              {/* <Checkbox>Remember Me</Checkbox> */}
              <a className="ms-auto" onClick={() => setShowForgotPasswordModal(true)}>Forgot password?</a>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Login
              </Button>
              {/* Button for Registration */}
              <Button type="default" style={{ marginTop: '20px' }} block className="mt-3" onClick={handleRegister}>
                Register
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Col>
      <Modal
        title="Forgot Password"
        visible={showForgotPasswordModal}
        onCancel={closeModal}
        footer={null}
        closable={true}
      >
        {error && <Alert message="Error" description={error} type="error" showIcon closable onClose={() => setError(null)} />}
        {forgotPasswordSuccess ? (
          <Alert message={forgotPasswordSuccess} type="success" showIcon closable onClose={() => setForgotPasswordSuccess(null)} />
        ) : (
          <Form layout="vertical" onFinish={handleForgotPassword}>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'Please enter a valid email address',
                },
                {
                  required: true,
                  message: 'Email address is required',
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Please enter your email address"
                value={forgotEmail}
                onChange={e => setForgotEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={forgotPasswordLoading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </Row>
  );
}

export default Login;
